// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Base from './base';

export default class Sun extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-sun'
  };

  render() {
    return (
      <Base {...this.props}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_85_57860)">
            <path d="M8 6.00244C8.39556 6.00244 8.78224 6.11974 9.11114 6.3395C9.44004 6.55927 9.69639 6.87162 9.84776 7.23707C9.99914 7.60253 10.0387 8.00466 9.96157 8.39262C9.8844 8.78058 9.69392 9.13695 9.41421 9.41665C9.13451 9.69636 8.77814 9.88684 8.39018 9.96401C8.00222 10.0412 7.60009 10.0016 7.23463 9.8502C6.86918 9.69883 6.55683 9.44248 6.33706 9.11358C6.1173 8.78468 6 8.398 6 8.00244C6.0006 7.47219 6.2115 6.96383 6.58645 6.58889C6.96139 6.21394 7.46975 6.00304 8 6.00244ZM8 5.00244C7.40666 5.00244 6.82664 5.17839 6.33329 5.50803C5.83994 5.83768 5.45543 6.30621 5.22836 6.85439C5.0013 7.40257 4.94189 8.00577 5.05765 8.58771C5.1734 9.16966 5.45912 9.7042 5.87868 10.1238C6.29824 10.5433 6.83279 10.829 7.41473 10.9448C7.99667 11.0606 8.59987 11.0011 9.14805 10.7741C9.69623 10.547 10.1648 10.1625 10.4944 9.66915C10.8241 9.1758 11 8.59579 11 8.00244C11 7.20679 10.6839 6.44373 10.1213 5.88112C9.55871 5.31851 8.79565 5.00244 8 5.00244Z" />
            <path d="M3.40381 2.69921L2.69678 3.40625L4.4497 5.15917L5.15673 4.45213L3.40381 2.69921Z" />
            <path d="M3.5 7.50244H1V8.50244H3.5V7.50244Z" />
            <path d="M4.4497 10.8457L2.69678 12.5986L3.40381 13.3057L5.15673 11.5528L4.4497 10.8457Z" />
            <path d="M8.5 12.5024H7.5V15.0024H8.5V12.5024Z" />
            <path d="M11.5503 10.8457L10.8433 11.5527L12.5962 13.3057L13.3032 12.5986L11.5503 10.8457Z" />
            <path d="M15 7.50244H12.5V8.50244H15V7.50244Z" />
            <path d="M12.5962 2.69923L10.8433 4.45215L11.5503 5.15918L13.3032 3.40627L12.5962 2.69923Z" />
            <path d="M8.5 1.00244H7.5V3.50244H8.5V1.00244Z" />
          </g>
          <defs>
            <clipPath id="clip0_85_57860">
              <rect width="16" height="16" />
            </clipPath>
          </defs>
        </svg>
      </Base>
    );
  }
}
