// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {ComponentType} from 'react';
import classnames from 'classnames';

import {MapControlButton} from '../common/styled-components';
import {Info} from '../common/icons';
import MapControlTooltipFactory from './map-control-tooltip';

HelpButtonFactory.deps = [MapControlTooltipFactory];

interface SplitMapButtonIcons {
  help: ComponentType<any>;
}

export type HelpButtonProps = {
  isActive: boolean;
  actionIcons: SplitMapButtonIcons;
  onToggleHelp: () => void;
};

function HelpButtonFactory(MapControlTooltip: ReturnType<typeof MapControlTooltipFactory>) {
  const HelpButton: React.FC<HelpButtonProps> = React.memo(({isActive, actionIcons, onToggleHelp}) => {
    return (
      <div className="help-panel-controls" style={{position: 'relative'}}>
        <MapControlTooltip id="locale" message="tooltip.help">
          <MapControlButton
            className={classnames('map-control-button', 'locale-panel', {isActive})}
            onClick={onToggleHelp}
            active={isActive}
          >
            <span className="help-button__locale" style={{fontSize: '1.5em', fontWeight: 'bold'}}>
              ?
            </span>
          </MapControlButton>
        </MapControlTooltip>
      </div>
    );
  });

  HelpButton.defaultProps = {
    actionIcons: {
      help: Info
    }
  };

  HelpButton.displayName = 'LocalePanel';

  return HelpButton;
}

export default HelpButtonFactory;
