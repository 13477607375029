// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Dispatch} from 'react';
import ReactDOM from 'react-dom/client';
import document from 'global/document';
import {Provider} from 'react-redux';
import store from './store.ts';
import App from './app.tsx';

import {asyncHelpers} from '@kepler.gl/reducers';

function initApp(dispatch: Dispatch<any>) {
  dispatch(asyncHelpers.fetchConfigs);
  dispatch(asyncHelpers.fetchTimeDomain);
}

initApp(store.dispatch);
const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Root />);
