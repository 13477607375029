// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {ObjectInfo} from '@deck.gl/core/lib/layer';

import {DataContainerInterface} from './data-container-interface';

const MS_IN_DAY: number = 86_400_000;

export function buildOneDayFilter(filters: number[][]): typeof filters {
  return Array.from({length: filters.length}, (_, idx) => {
    if (idx !== 0) {
      return filters[idx];
    }

    return [filters[0][1], filters[0][1] + MS_IN_DAY];
    //                                     ↑ Take the point from the last 24 hours
  });
}

export function bufferFromAccessor<T extends ArrayLike<number> & {set: Float32Array['set']}>(
  dataContainer: DataContainerInterface,
  data: any,
  accessor: (data: any, objectInfo: ObjectInfo<any, any>) => Array<number>,
  TArray: new (size: number) => T,
  nbChannels: number
): T {
  const nbRows = dataContainer.numRows();

  const filterValuesBuf = new TArray(nbRows * nbChannels);
  const isDataDefined = Array.isArray(data);
  for (let rowIdx = 0; rowIdx < nbRows; ++rowIdx) {
    const bufferIdx = rowIdx * nbChannels;
    let channelsValues = new Array(nbChannels);
    const objectInfo = {
      index: rowIdx,
      data,
      target: channelsValues
    };
    channelsValues = accessor(isDataDefined ? data[rowIdx] : undefined, objectInfo);
    filterValuesBuf.set(channelsValues, bufferIdx);
  }

  return filterValuesBuf;
}
