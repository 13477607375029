// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

/**
 * Follow the model of './data-table-modal.tsx'
 */

import styled from 'styled-components';
import React, {Component} from 'react';
import {injectIntl, IntlShape, WrappedComponentProps} from 'react-intl';

enum Tab {
  PresentationTab,
  HelpTab
}

const HelpModalContainer = styled.div`
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  margin: min(20px, 1%);
`;

const HelpModalTabContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
`;

export const HelpModalTab = styled.div<{active?: boolean}>`
  align-items: center;
  border-bottom: 3px solid ${({active = false}) => (active ? 'black' : 'transparent')};
  cursor: pointer;
  display: flex;
  height: 35px;
  margin: 0 3px;
  padding: 0 5px;

  :hover {
    border-bottom: 3px solid black;
  }
`;

export type HelpModalProps = {} & WrappedComponentProps;

HelpModalFactory.deps = [];

const PresentationTab: React.FC<{intl: IntlShape}> = ({intl}) => {
  const content = intl.formatMessage({id: 'helpModal.presentation.content'}).trim();
  const FormattedContent = content.split(/\n|\r|\n\r/).flatMap((paragraph) => (
    <>
      {paragraph}
      <br />
    </>
  ));

  return (
    <>
      <h1>{intl.formatMessage({id: 'helpModal.presentation.title'})}</h1>
      <p>{FormattedContent}</p>
    </>
  );
};

const HelpTab: React.FC<{intl: IntlShape}> = ({intl}) => (
  <>
    <h1>{intl.formatMessage({id: 'helpModal.help.title'})}</h1>
    <p> Empty </p>
  </>
);

export function HelpModalFactory() {
  class HelpModal extends Component<HelpModalProps> {
    constructor(props: HelpModalProps) {
      super(props);

      this.state = {
        currentTab: Tab.PresentationTab
      };
    }

    state: {
      currentTab: Tab;
    };

    render() {
      const {intl} = this.props;
      const CurrentTab = () => (
        <>
          {this.state.currentTab === Tab.PresentationTab ? (
            <PresentationTab intl={intl} />
          ) : (
            <HelpTab intl={intl} />
          )}
        </>
      );
      return (
        <HelpModalContainer>
          <HelpModalTabContainer>
            <HelpModalTab
              active={this.state.currentTab === Tab.PresentationTab}
              onClick={() => this.setState({currentTab: Tab.PresentationTab})}
            >
              {intl.formatMessage({id: 'helpModal.presentation.title'})}
            </HelpModalTab>
            <HelpModalTab
              active={this.state.currentTab === Tab.HelpTab}
              onClick={() => this.setState({currentTab: Tab.HelpTab})}
            >
              {intl.formatMessage({id: 'helpModal.help.title'})}
            </HelpModalTab>
          </HelpModalTabContainer>
          <CurrentTab />
        </HelpModalContainer>
      );
    }
  }

  return injectIntl(HelpModal);
}

export default HelpModalFactory;
