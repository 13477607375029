// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {parse} from '@loaders.gl/core';
import {ArrowLoader} from '@loaders.gl/arrow';
import {ArrowTable} from '@loaders.gl/schema';

import {TurtleConfig, AdditionalConfig} from '../../types';
import {
  loadingTurtleConfigsEnded,
  loadingAdditionalConfigsEnded,
  LoadedTurtleDataEntry,
  LoadedAdditionalDataEntry,
  setTimebarDomain
} from '../../actions/src/streaming-actions';
import {
  TURTLE_DATA_URL,
  TURTLE_CONFIG_URL,
  NESTING_AREA_URL,
  ADDITIONAL_CONFIG_URL,
  ADDITIONAL_DATA_URL,
  DEFAULT_NB_TURTLES,
  TIME_RANGE_URL
} from '../../constants/src/network';

import {Dispatch} from 'react';
import {DateTime, DurationLike, Interval} from 'luxon';

export const CHUNK_SIZE: DurationLike = {
  month: 1
};
export const DOWNLOAD_DELAY: DurationLike = {
  week: 1
};

export function buildTurtleKeplerLayerID(config: TurtleConfig): string {
  return 'turtles_trajectories' + '_' + config.id;
}

export function buildTurtleKeplerLayerLabel(config: TurtleConfig) {
  // en dur dans le layer manager pour la livraison
  //return config.location + ' ' + config.configuration;
  return 'Trajectories';
}

export async function fetchConfigs(dispatch: Dispatch<any>) {
  fetchTurtleConfigs(dispatch);
  fetchAdditionalDataConfigs(dispatch);
}

export async function fetchTurtleConfigs(dispatch: Dispatch<any>) {
  const availableConfigs: TurtleConfig[] = await fetch(TURTLE_CONFIG_URL).then((res) => res.json());

  dispatch(loadingTurtleConfigsEnded({turtleConfigs: availableConfigs}));
}

export async function fetchAdditionalDataConfigs(dispatch: Dispatch<any>) {
  const availableConfigs: AdditionalConfig[] = await fetch(ADDITIONAL_CONFIG_URL).then((res) =>
    res.json()
  );

  dispatch(loadingAdditionalConfigsEnded({additionalConfigs: availableConfigs}));
}

export async function fetchTurtleDataset(
  configID: string,
  interval: Interval,
  p_nbTurtle?: number
): Promise<LoadedTurtleDataEntry> {
  const firstDate = interval.start;
  const lastDate = interval.end;
  const nbTurtle = p_nbTurtle || DEFAULT_NB_TURTLES;

  if (firstDate === null || lastDate === null) {
    throw new Error('firstDate or lastDate is null');
  }

  const searchParams: Record<string, string> = {
    startDay: firstDate.toISODate(),
    endDay: lastDate.toISODate(),
    nbTurtle: (nbTurtle ?? DEFAULT_NB_TURTLES).toString()
  };
  const dataUrl =
    TURTLE_DATA_URL + '/' + configID + '?' + new URLSearchParams(searchParams).toString();

  const table = (await fetch(dataUrl)
    .then((data) => data.arrayBuffer())
    .then((buf) => new Uint8Array(buf))
    .then((buf) =>
      parse(buf, ArrowLoader, {
        arrow: {
          shape: 'arrow-table'
        }
      })
    )) as ArrowTable;

  return {
    datasetId: configID,
    table,
    chunkTime: interval,
    nbTurtle
  };
}

export async function fetchNestingArea(config: TurtleConfig): Promise<any> {
  const response = await fetch(NESTING_AREA_URL + '/' + config.id);

  if (!response.ok) {
    console.error(`Failed to fetch nesting area for config ${config.id}`);
    return null;
  }

  const geojson = await response.json();
  if (!geojson || !geojson.type) {
    console.error(`Invalid geojson data for config ${config.id}`);
    return null;
  }

  return {config, geojson};
}

export async function fetchAdditionalDataset(
  config: AdditionalConfig
): Promise<LoadedAdditionalDataEntry> {
  const dataUrl = ADDITIONAL_DATA_URL + '/' + config.id;

  const table = await fetch(dataUrl)
    .then((data) => data.json())
    .catch((err) => {
      console.error(err);
      return undefined;
    });

  return {
    config,
    json: table
  };
}

export async function fetchTimeDomain(dispatch: Dispatch<any>) {
  const newDomain: [Date, Date] = await fetch(TIME_RANGE_URL)
    .then((data) => data.json())
    .then((obj): [string, string] => {
      const dateStrs = [obj['firstDate'], obj['lastDate']] as [string, string];
      return dateStrs;
    })
    .then((data) => {
      const domain = data.map((dateStr) => DateTime.fromISO(dateStr).toJSDate()) as [Date, Date];
      return domain;
    });

  const action = setTimebarDomain({newDomain});
  dispatch(action);
}
