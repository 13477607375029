// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React from 'react';
import {connect} from 'react-redux';
import KeplerGl from '@kepler.gl/components';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import TimeUI from './timeUi';
import * as maplibre from 'maplibre-gl';
import {MapStateActions} from '@kepler.gl/actions';

const localeMessages = {
  en: {
    mapLayers: {
      terrain: 'Terrain'
    }
  }
};

const App = ({dispatch}) => {
  const handleGetMapboxRef = ref => {
    if (!ref) {
      return;
    }

    const map: maplibre.Map = ref.getMap();
    if (!map) {
      return;
    }

    dispatch(MapStateActions.updateMapRef({map: map}));
  };

  return (
    <div style={{position: 'absolute', width: '100%', height: '100%'}}>
      <AutoSizer>
        {({height, width}) => (
          <div style={{position: 'relative', width: '100%', height: '100%'}}>
            <KeplerGl
              mapboxApiAccessToken="your_mapbox_token"
              id="map"
              width={width}
              height={height}
              localeMessages={localeMessages}
              appName="3T - Turtle Tracking Tool"
              version="1.0.0"
              appWebsite="https://mercator.geomatys.com/"
              getMapboxRef={handleGetMapboxRef}
              interactionConfig={{}}
            />
          </div>
        )}
      </AutoSizer>
      {/* <TimeUI /> */}
    </div>
  );
};

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({dispatch});

export default connect(mapStateToProps, mapDispatchToProps)(App);
