// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {useState} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

import {FormattedMessage} from '@kepler.gl/localization';

import {Tooltip} from '../styled-components';
import IconButton from '../icon-button';

const DELAY_SHOW = 500;

// const DropDownMenu = styled.div`
//   color: black;
//   position: relative;
//   background-color: #fff;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   padding: 5px 0;
//   margin: 0;
//   z-index: 2;
//   min-width: 75px;
//   overflow-y: auto;
//   margin-bottom: 100px;
// `;

const DropDownMenu = styled.div`
  color: black;
  position: absolute;
  right: calc(0% - 32px);
  bottom: 50px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 2px 8px 2px 12px;
  margin: 0;
  z-index: 2;
  min-width: 75px;
  overflow-y: auto;
  margin-bottom: 5px;
`;

// const DropDownMenu = styled.div`
//   background-color: ${props => props.theme.bottomWidgetBgd};
//   box-shadow: -2px -2px 0 0 rgba(0, 0, 0, 0.1);
// `

const DropDownItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  font-size: 0.9rem;
  background-color: #fff;
  border: none;
  &:hover {
    background-color: #f2f2f2;
  }
`;

function RangeControlFactory() {
  const RangeControl = ({
    showAnimationWindowControl,
    onRangeChanged = () => {},
    btnStyle,
    playbackIcons,
    buttonHeight
  }: {
    showAnimationWindowControl: any;
    onRangeChanged: (range: number) => void;
    btnStyle: any;
    playbackIcons: any;
    buttonHeight: any;
  }) => {
    const [showRangeSelector, setShowRangeSelector] = useState<boolean>(false);

    const _onRangeSelected = (range: number): void => {
      setShowRangeSelector(false);
      onRangeChanged(range);
    };

    const _onBtnClicked = (): void => {
      setShowRangeSelector(!showRangeSelector);
    };

    return (
      <>
        <IconButton
          data-tip
          data-for="time-control-range"
          className={classnames('playback-control-button')}
          onClick={_onBtnClicked}
          {...btnStyle}
        >
          <playbackIcons.range height={buttonHeight} />
          <Tooltip id="time-control-range" place="top" delayShow={DELAY_SHOW} effect="solid">
            <FormattedMessage id={'tooltip.range'} />
          </Tooltip>
        </IconButton>
        {showRangeSelector && (
          <DropDownMenu>
            {[1, 7, 31, 90, 180, 365].map((range) => (
              <DropDownItem key={range} onClick={() => _onRangeSelected(range)}>
                <FormattedMessage id="timebar.range" values={{range: range}} />
              </DropDownItem>
            ))}
          </DropDownMenu>
        )}
      </>
    );
  };

  return RangeControl;
}

export default RangeControlFactory;
