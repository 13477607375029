// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import Base, {BaseProps} from './base';

export default class Share extends Component<Partial<BaseProps>> {
  static defaultProps = {
    height: '16px',
    predefinedClassName: 'data-ex-icons-share'
  };

  render() {
    return (
      <Base {...this.props}>
        <path d="M58 50c0 5.523-4.477 10-10 10s-10-4.477-10-10c0-.432.037-.854.09-1.272L22.236 39.81A9.95 9.95 0 0 1 16 42c-5.523 0-10-4.477-10-10s4.477-10 10-10a9.95 9.95 0 0 1 6.236 2.19l15.854-8.918A10.03 10.03 0 0 1 38 14c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10a9.95 9.95 0 0 1-6.236-2.19L25.91 30.728c.053.418.09.84.09 1.272s-.037.854-.09 1.272l15.854 8.918A9.95 9.95 0 0 1 48 40c5.523 0 10 4.477 10 10z" />
      </Base>
    );
  }
}
