// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import Base, {BaseProps} from './base';

export default class Empty extends Component<Partial<BaseProps>> {
  static defaultProps = {
    height: '0px',
    predefinedClassName: 'data-ex-icons-empty'
  };

  render() {
    return (
      <Base {...this.props}>
        <g transform="translate(8, 8)">
          <path d="" />
        </g>
      </Base>
    );
  }
}
