// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import styled from 'styled-components';

import Slider, {SliderProps} from './slider';
import SliderMarkers, {SliderMarkersProps} from './slider-markers';

const MarkedSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export type MarkedSliderProps = SliderMarkersProps & SliderProps;
export class MarkedSlider extends Component<MarkedSliderProps> {
  static defaultProps = {
    ...Slider.defaultProps
  };
  constructor(props: MarkedSliderProps) {
    super(props);
  }

  override render() {
    return (
      <MarkedSliderWrapper>
        <SliderMarkers {...this.props} />
        <Slider {...this.props} />
      </MarkedSliderWrapper>
    );
  }
}

export default MarkedSlider;
