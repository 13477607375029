// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Base from '../base';

export default class DotScreen extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-dot-screen'
  };

  render() {
    return (
      <Base {...this.props}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_129_11268)">
            <path d="M4.34288 4.95245C4.34288 5.62571 3.79709 6.17149 3.12383 6.17149C2.45057 6.17149 1.90479 5.62571 1.90479 4.95245C1.90479 4.27918 2.45057 3.7334 3.12383 3.7334C3.79709 3.7334 4.34288 4.27918 4.34288 4.95245Z" />
            <path d="M4.34288 11.0477C4.34288 11.7209 3.79709 12.2667 3.12383 12.2667C2.45057 12.2667 1.90479 11.7209 1.90479 11.0477C1.90479 10.3744 2.45057 9.82861 3.12383 9.82861C3.79709 9.82861 4.34288 10.3744 4.34288 11.0477Z" />
            <path d="M5.86668 1.90484C5.86668 2.5781 5.3209 3.12389 4.64764 3.12389C3.97438 3.12389 3.42859 2.5781 3.42859 1.90484C3.42859 1.23158 3.97438 0.685791 4.64764 0.685791C5.3209 0.685791 5.86668 1.23158 5.86668 1.90484Z" />
            <path d="M2.51427 1.90484C2.51427 2.5781 1.96848 3.12389 1.29522 3.12389C0.621958 3.12389 0.0761719 2.5781 0.0761719 1.90484C0.0761719 1.23158 0.621958 0.685791 1.29522 0.685791C1.96848 0.685791 2.51427 1.23158 2.51427 1.90484Z" />
            <path d="M5.86668 8.00005C5.86668 8.67332 5.3209 9.2191 4.64764 9.2191C3.97438 9.2191 3.42859 8.67332 3.42859 8.00005C3.42859 7.32679 3.97438 6.78101 4.64764 6.78101C5.3209 6.78101 5.86668 7.32679 5.86668 8.00005Z" />
            <path d="M2.51427 8.00005C2.51427 8.67332 1.96848 9.2191 1.29522 9.2191C0.621958 9.2191 0.0761719 8.67332 0.0761719 8.00005C0.0761719 7.32679 0.621958 6.78101 1.29522 6.78101C1.96848 6.78101 2.51427 7.32679 2.51427 8.00005Z" />
            <path d="M5.86668 14.0953C5.86668 14.7685 5.3209 15.3143 4.64764 15.3143C3.97438 15.3143 3.42859 14.7685 3.42859 14.0953C3.42859 13.422 3.97438 12.8762 4.64764 12.8762C5.3209 12.8762 5.86668 13.422 5.86668 14.0953Z" />
            <path d="M2.51427 14.0953C2.51427 14.7685 1.96848 15.3143 1.29522 15.3143C0.621958 15.3143 0.0761719 14.7685 0.0761719 14.0953C0.0761719 13.422 0.621958 12.8762 1.29522 12.8762C1.96848 12.8762 2.51427 13.422 2.51427 14.0953Z" />
            <path d="M7.6953 4.95245C7.6953 5.62571 7.14951 6.17149 6.47625 6.17149C5.80299 6.17149 5.2572 5.62571 5.2572 4.95245C5.2572 4.27918 5.80299 3.7334 6.47625 3.7334C7.14951 3.7334 7.6953 4.27918 7.6953 4.95245Z" />
            <path d="M7.6953 11.0477C7.6953 11.7209 7.14951 12.2667 6.47625 12.2667C5.80299 12.2667 5.2572 11.7209 5.2572 11.0477C5.2572 10.3744 5.80299 9.82861 6.47625 9.82861C7.14951 9.82861 7.6953 10.3744 7.6953 11.0477Z" />
            <path d="M9.2191 1.90484C9.2191 2.5781 8.67332 3.12389 8.00005 3.12389C7.32679 3.12389 6.78101 2.5781 6.78101 1.90484C6.78101 1.23158 7.32679 0.685791 8.00005 0.685791C8.67332 0.685791 9.2191 1.23158 9.2191 1.90484Z" />
            <path d="M9.2191 8.00005C9.2191 8.67332 8.67332 9.2191 8.00005 9.2191C7.32679 9.2191 6.78101 8.67332 6.78101 8.00005C6.78101 7.32679 7.32679 6.78101 8.00005 6.78101C8.67332 6.78101 9.2191 7.32679 9.2191 8.00005Z" />
            <path d="M9.2191 14.0953C9.2191 14.7685 8.67332 15.3143 8.00005 15.3143C7.32679 15.3143 6.78101 14.7685 6.78101 14.0953C6.78101 13.422 7.32679 12.8762 8.00005 12.8762C8.67332 12.8762 9.2191 13.422 9.2191 14.0953Z" />
            <path d="M11.0476 4.95245C11.0476 5.62571 10.5018 6.17149 9.82854 6.17149C9.15528 6.17149 8.6095 5.62571 8.6095 4.95245C8.6095 4.27918 9.15528 3.7334 9.82854 3.7334C10.5018 3.7334 11.0476 4.27918 11.0476 4.95245Z" />
            <path d="M11.0476 11.0477C11.0476 11.7209 10.5018 12.2667 9.82854 12.2667C9.15528 12.2667 8.6095 11.7209 8.6095 11.0477C8.6095 10.3744 9.15528 9.82861 9.82854 9.82861C10.5018 9.82861 11.0476 10.3744 11.0476 11.0477Z" />
            <path d="M12.5714 1.90484C12.5714 2.5781 12.0256 3.12389 11.3523 3.12389C10.6791 3.12389 10.1333 2.5781 10.1333 1.90484C10.1333 1.23158 10.6791 0.685791 11.3523 0.685791C12.0256 0.685791 12.5714 1.23158 12.5714 1.90484Z" />
            <path d="M12.5714 8.00005C12.5714 8.67332 12.0256 9.2191 11.3523 9.2191C10.6791 9.2191 10.1333 8.67332 10.1333 8.00005C10.1333 7.32679 10.6791 6.78101 11.3523 6.78101C12.0256 6.78101 12.5714 7.32679 12.5714 8.00005Z" />
            <path d="M12.5714 14.0953C12.5714 14.7685 12.0256 15.3143 11.3523 15.3143C10.6791 15.3143 10.1333 14.7685 10.1333 14.0953C10.1333 13.422 10.6791 12.8762 11.3523 12.8762C12.0256 12.8762 12.5714 13.422 12.5714 14.0953Z" />
            <path d="M14.4 4.95245C14.4 5.62571 13.8542 6.17149 13.181 6.17149C12.5077 6.17149 11.9619 5.62571 11.9619 4.95245C11.9619 4.27918 12.5077 3.7334 13.181 3.7334C13.8542 3.7334 14.4 4.27918 14.4 4.95245Z" />
            <path d="M14.4 11.0477C14.4 11.7209 13.8542 12.2667 13.181 12.2667C12.5077 12.2667 11.9619 11.7209 11.9619 11.0477C11.9619 10.3744 12.5077 9.82861 13.181 9.82861C13.8542 9.82861 14.4 10.3744 14.4 11.0477Z" />
            <path d="M15.9238 1.90484C15.9238 2.5781 15.378 3.12389 14.7048 3.12389C14.0315 3.12389 13.4857 2.5781 13.4857 1.90484C13.4857 1.23158 14.0315 0.685791 14.7048 0.685791C15.378 0.685791 15.9238 1.23158 15.9238 1.90484Z" />
            <path d="M15.9238 8.00005C15.9238 8.67332 15.378 9.2191 14.7048 9.2191C14.0315 9.2191 13.4857 8.67332 13.4857 8.00005C13.4857 7.32679 14.0315 6.78101 14.7048 6.78101C15.378 6.78101 15.9238 7.32679 15.9238 8.00005Z" />
            <path d="M15.9238 14.0953C15.9238 14.7685 15.378 15.3143 14.7048 15.3143C14.0315 15.3143 13.4857 14.7685 13.4857 14.0953C13.4857 13.422 14.0315 12.8762 14.7048 12.8762C15.378 12.8762 15.9238 13.422 15.9238 14.0953Z" />
          </g>
          <defs>
            <clipPath id="clip0_129_11268">
              <rect width="12.1905" height="12.1905" transform="translate(1.90479 1.90479)" />
            </clipPath>
          </defs>
        </svg>
      </Base>
    );
  }
}
