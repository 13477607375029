// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Base from '../base';

export default class Ink extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-ink'
  };

  render() {
    return (
      <Base {...this.props}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_129_11263)">
            <path d="M11.3656 10.5766C10.8658 11.4055 10.2342 11.142 9.64103 10.7907C9.04785 10.4394 8.48763 10.0168 8.13062 10.5163C7.74066 10.9444 8.04275 11.6305 8.19653 12.2343C8.35032 12.8435 8.3613 13.3704 7.38915 13.4857C6.62021 13.3814 6.66415 12.7447 6.79048 12.0696C6.9168 11.3945 7.11453 10.6809 6.62021 10.4284C6.22476 10.165 5.95014 10.6206 5.60412 11.0706C5.24712 11.5152 4.84068 11.9488 4.17061 11.6305C3.56644 11.131 3.79712 10.8401 4.11568 10.4669C4.43424 10.0936 4.84617 9.59415 4.61 8.71594C4.51113 8.35917 4.01682 8.4415 3.528 8.43052C3.05016 8.41954 2.56683 8.32074 2.5174 7.6072C2.47895 7.17358 2.803 6.99794 3.13804 6.86072C3.47856 6.7235 3.82459 6.6247 3.83008 6.27891C3.84655 5.94409 3.62137 5.70807 3.47856 5.46108C3.33576 5.21408 3.27535 4.95611 3.61587 4.57189C4.19258 4.09985 4.65394 4.50054 5.1153 4.93415C5.57666 5.36777 6.03253 5.82334 6.59825 5.46657C7.04862 5.17017 6.63669 4.64873 6.31813 4.13828C5.99957 3.62782 5.77439 3.13931 6.59825 2.91427C7.31226 2.71668 7.58688 3.20518 7.83403 3.77053C8.08668 4.33587 8.30638 4.97806 8.91054 5.09333C9.77285 5.2525 10.399 4.24256 10.9977 3.4412C11.5963 2.63983 12.184 2.04155 12.9365 3.02954C13.7603 4.06692 12.9749 4.54994 12.0577 4.97806C11.1405 5.37875 10.0859 5.73003 10.3386 6.50395C10.4869 6.95403 10.9702 6.70704 11.481 6.53688C11.9863 6.36673 12.53 6.27342 12.7937 7.04734C13.0738 7.86517 12.4092 8.05179 11.6622 8.12864C10.9153 8.20548 10.0804 8.17255 10.0255 8.54579C9.96508 8.93549 10.4649 9.09467 10.8988 9.3252C11.3327 9.56121 11.7007 9.87408 11.3656 10.5766ZM12.6618 11.8391C12.1401 11.8391 11.8709 11.4329 11.8709 11.0157C11.8709 10.5986 12.1346 10.1924 12.6618 10.1924C13.2111 10.1924 13.4857 10.5986 13.4857 11.0157C13.4857 11.4329 13.2111 11.8391 12.6618 11.8391Z" />
          </g>
          <defs>
            <clipPath id="clip0_129_11263">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Base>
    );
  }
}
