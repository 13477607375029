// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Dispatch, useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import document from 'global/document';
import {Provider} from 'react-redux';
import {DateTime, Interval} from 'luxon';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import {streamingHelpers} from '@kepler.gl/reducers';
import {StreamingAppType} from '@kepler.gl/schemas';
import {loadingDataEnded, LoadedDataEntry, VisStateActions} from '@kepler.gl/actions';

import {initKeplerState} from './store';
import App from './app';

function initTurtleApp(dispatch: Dispatch<any>) {
  dispatch(streamingHelpers.turtle.fetchConfigs);
  dispatch(streamingHelpers.turtle.fetchTimeDomain);
}

function initPlasticApp(dispatch: Dispatch<any>) {
  dispatch(streamingHelpers.plastic.fetchTimeDomain);
  const fetchDataTask = async (dispatch) => {
    const rawDataEntry = await streamingHelpers.plastic.fetchPlasticData(
      Interval.fromDateTimes(DateTime.fromISO('2010-01-01'), DateTime.fromISO('2010-02-01'))
    );
    const dataEntry = {
      type: 'plastic-data',
      ...rawDataEntry
    } as LoadedDataEntry;
    const task = loadingDataEnded({data: [dataEntry]});
    dispatch(task);
    dispatch(streamingHelpers.additionnal.fetchAdditionalDataConfigs);
    dispatch(VisStateActions.updateLayerBlending("additive"));
  };
  dispatch(fetchDataTask);
}

const AppLoader = ({ appType }: { appType: StreamingAppType }) => {
  const [store, setStore] = useState<undefined | ReturnType<typeof initKeplerState>>(undefined);
  let appName = "App"

  switch(appType)
  {
    case 'turtle':
      appName = "Simulated Sea Turtle Swimways"
      break;
    case 'plastic':
      appName = "Simulated Plastic Drift"
      break;
  }

  useEffect(() => {
    const store = initKeplerState(appType);
    setStore(store);

    switch (appType) {
      case 'plastic':
        // @ts-expect-error Action is needed but redux-thunk allow functions
        store.dispatch(initPlasticApp);
        break;
      case 'turtle':
        // @ts-expect-error Action is needed but redux-thunk allow functions
        store.dispatch(initTurtleApp);
        break;
    }
  }, [appType]);

  if (!store) {
    return <div>Loading...</div>; // Show a loading state while the app initializes
  }

  return (
    <Provider store={store}>
      <App appName={appName}/>
    </Provider>
  );
};

const Root = () => {
  return (
    <Router>
      <Routes>
        {/* Default redirection to /turtles if no route is provided */}
        <Route path="/" element={<Navigate to="/turtles" replace />} />

        {/* Route for Turtles */}
        <Route path="/turtles" element={<AppLoader appType="turtle" />} />

        {/* Route for Plastic */}
        <Route path="/plastics" element={<AppLoader appType="plastic" />} />

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Navigate to="/turtles" replace />} />
      </Routes>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<Root />);
