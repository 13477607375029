// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Base from '../base';

export default class HexagonalPixelate extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-hexagonal-pixelate'
  };

  render() {
    return (
      <Base {...this.props}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_129_11277)">
            <path
              opacity="0.5"
              d="M4.91606 1.29938L3.36296 0.376709L1.81042 1.29835L1.811 3.14267L3.36411 4.06534L4.91664 3.14369L4.91606 1.29938Z"
            />
            <path
              opacity="0.5"
              d="M4.91606 6.73529L3.36296 5.81262L1.81042 6.73427L1.811 8.57858L3.36411 9.50125L4.91664 8.5796L4.91606 6.73529Z"
            />
            <path
              opacity="0.5"
              d="M4.91606 12.1711L3.36296 11.2484L1.81042 12.1701L1.811 14.0144L3.36411 14.937L4.91664 14.0154L4.91606 12.1711Z"
            />
            <path
              opacity="0.7"
              d="M3.36296 4.01727L1.80986 3.0946L0.257324 4.01625L0.257899 5.86056L1.811 6.78323L3.36354 5.86159L3.36296 4.01727Z"
            />
            <path
              opacity="0.7"
              d="M3.36296 9.45319L1.80986 8.53052L0.257324 9.45216L0.257899 11.2965L1.811 12.2191L3.36354 11.2975L3.36296 9.45319Z"
            />
            <path
              opacity="0.5"
              d="M3.36296 14.8891L1.80986 13.9664L0.257324 14.8881L0.257899 16.7324L1.811 17.6551L3.36354 16.7334L3.36296 14.8891Z"
            />
            <path
              opacity="0.7"
              d="M8.02226 1.29938L6.46916 0.376709L4.91663 1.29835L4.9172 3.14267L6.47031 4.06534L8.02284 3.14369L8.02226 1.29938Z"
            />
            <path
              opacity="0.7"
              d="M8.02226 6.73529L6.46916 5.81262L4.91663 6.73427L4.9172 8.57858L6.47031 9.50125L8.02284 8.5796L8.02226 6.73529Z"
            />
            <path
              opacity="0.7"
              d="M8.02226 12.1711L6.46916 11.2484L4.91663 12.1701L4.9172 14.0144L6.47031 14.937L8.02284 14.0154L8.02226 12.1711Z"
            />
            <path d="M6.46916 4.01727L4.91606 3.0946L3.36353 4.01625L3.3641 5.86056L4.91721 6.78323L6.46974 5.86159L6.46916 4.01727Z" />
            <path d="M6.46916 9.45319L4.91606 8.53052L3.36353 9.45216L3.3641 11.2965L4.91721 12.2191L6.46974 11.2975L6.46916 9.45319Z" />
            <path d="M6.46916 14.8891L4.91606 13.9664L3.36353 14.8881L3.3641 16.7324L4.91721 17.6551L6.46974 16.7334L6.46916 14.8891Z" />
            <path d="M11.1285 1.29938L9.57536 0.376709L8.02283 1.29835L8.0234 3.14267L9.57651 4.06534L11.129 3.14369L11.1285 1.29938Z" />
            <path d="M11.1285 6.73529L9.57536 5.81262L8.02283 6.73427L8.0234 8.57858L9.57651 9.50125L11.129 8.5796L11.1285 6.73529Z" />
            <path d="M11.1285 12.1711L9.57536 11.2484L8.02283 12.1701L8.0234 14.0144L9.57651 14.937L11.129 14.0154L11.1285 12.1711Z" />
            <path
              opacity="0.5"
              d="M9.57536 4.01727L8.02226 3.0946L6.46973 4.01625L6.4703 5.86056L8.02341 6.78323L9.57594 5.86159L9.57536 4.01727Z"
            />
            <path
              opacity="0.5"
              d="M9.57536 9.45319L8.02226 8.53052L6.46973 9.45216L6.4703 11.2965L8.02341 12.2191L9.57594 11.2975L9.57536 9.45319Z"
            />
            <path
              opacity="0.3"
              d="M9.57536 14.8891L8.02226 13.9664L6.46973 14.8881L6.4703 16.7324L8.02341 17.6551L9.57594 16.7334L9.57536 14.8891Z"
            />
            <path
              opacity="0.5"
              d="M14.2347 1.29938L12.6816 0.376709L11.129 1.29835L11.1296 3.14267L12.6827 4.06534L14.2352 3.14369L14.2347 1.29938Z"
            />
            <path
              opacity="0.5"
              d="M14.2347 6.73529L12.6816 5.81262L11.129 6.73427L11.1296 8.57858L12.6827 9.50125L14.2352 8.5796L14.2347 6.73529Z"
            />
            <path
              opacity="0.5"
              d="M14.2347 12.1711L12.6816 11.2484L11.129 12.1701L11.1296 14.0144L12.6827 14.937L14.2352 14.0154L14.2347 12.1711Z"
            />
            <path
              opacity="0.7"
              d="M12.6816 4.01727L11.1285 3.0946L9.57593 4.01625L9.5765 5.86056L11.1296 6.78323L12.6821 5.86159L12.6816 4.01727Z"
            />
            <path d="M15.7878 4.01727L14.2347 3.0946L12.6821 4.01625L12.6827 5.86056L14.2358 6.78323L15.7883 5.86159L15.7878 4.01727Z" />
            <path
              opacity="0.7"
              d="M12.6816 9.45319L11.1285 8.53052L9.57593 9.45216L9.5765 11.2965L11.1296 12.2191L12.6821 11.2975L12.6816 9.45319Z"
            />
            <path
              opacity="0.5"
              d="M12.6816 14.8891L11.1285 13.9664L9.57593 14.8881L9.5765 16.7324L11.1296 17.6551L12.6821 16.7334L12.6816 14.8891Z"
            />
            <path d="M15.7878 9.45319L14.2347 8.53052L12.6821 9.45216L12.6827 11.2965L14.2358 12.2191L15.7883 11.2975L15.7878 9.45319Z" />
            <path d="M15.7878 14.8891L14.2347 13.9664L12.6821 14.8881L12.6827 16.7324L14.2358 17.6551L15.7883 16.7334L15.7878 14.8891Z" />
          </g>
          <defs>
            <clipPath id="clip0_129_11277">
              <rect width="12.1905" height="12.1905" transform="translate(1.90479 1.90479)" />
            </clipPath>
          </defs>
        </svg>
      </Base>
    );
  }
}
