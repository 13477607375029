// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {
  addLayerUpdater,
  updateVisDataUpdater,
  layerConfigChangeUpdater,
  addFilterUpdater,
  setFilterUpdater,
  setFilterAnimationTimeUpdater,
  setFilterAnimationTimeConfigUpdater,
  setFilterViewUpdater,
  KeplerGlState,
  MapStyle
} from '@kepler.gl/reducers';
import {
  MapState,
  ProtoDataset,
  RGBColor,
  SourceDatas,
  StreamedData,
  UiState
} from '@kepler.gl/types';
import {
  LoadingDataEndedPayload,
  LoadingTurtleConfigsEndedPayload,
  LoadingAdditionalConfigsEndedPayload,
  loadingDataEnded,
  loadingNestEnded,
  LoadingNestEndedPayload,
  LoadedTurtleDataEntry,
  LoadedDataEntry,
  LoadedAdditionalDataEntry,
  SetTurtleNumberAction,
  checkDownloadDatasetsTask,
  UpdateRandomColorPaletteAction,
  UpdateDatePayload,
  SetTimebarDomainPayload,
  playerTimerTick,
  updateDate,
  UpdateVisualDatePayload,
  SetPlayerTimeStepPayload
} from '@kepler.gl/actions';
import {processArrowTable, processGeojson} from '@kepler.gl/processors';
import {VisState} from '@kepler.gl/schemas';
import {LayerColumns} from '@kepler.gl/layers';
import {GeoJsonLayerConfig} from '@kepler.gl/layers/src/geojson-layer/geojson-layer';
import {
  QUA,
  NESTING_ICON_URL,
  DEFAULT_NB_TURTLES,
  DAYS_TO_PREFETCH,
  PLAYER_TICKRATE_MS
} from '@kepler.gl/constants';
import {rgbToHex} from '@kepler.gl/utils';
import {ACTION_TASK, DELAY_TASK} from '@kepler.gl/tasks';

import {DateTime, DurationLike, Interval} from 'luxon';
import Task, {withTask} from 'react-palm/tasks';
import {DeepPartial} from 'redux';
import {RasterTileSource} from 'maplibre-gl';

import {
  buildTurtleKeplerLayerID,
  buildTurtleKeplerLayerLabel,
  fetchTurtleDataset,
  fetchAdditionalDataset,
  DOWNLOAD_DELAY,
  fetchNestingArea
} from './async-init';

const generateRandomColorPalette = (nColor: number, oldPalette?: string[]): string[] => {
  const getRandomColor = () =>
    rgbToHex(Array.from({length: 3}, () => Math.floor(Math.random() * 255)) as RGBColor);
  let newPalette: string[];
  if (oldPalette === undefined) {
    newPalette = Array.from({length: nColor}, getRandomColor);
  } else if (oldPalette.length < nColor) {
    newPalette = Array.from({length: nColor}, (_: unknown, idx: number) => {
      let color: string;
      if (idx < oldPalette.length) {
        color = oldPalette[idx];
      } else {
        color = getRandomColor();
      }
      return color;
    });
  } else if (oldPalette.length > nColor) {
    newPalette = oldPalette.slice(0, nColor);
  } else {
    newPalette = oldPalette;
  }

  return newPalette;
};

export const checkDownloadDatasetsUpdater = (state: KeplerGlState, {}: {}): KeplerGlState => {
  let {visState} = state;
  const {mapStyle} = state;

  const lastDate = DateTime.fromJSDate(mapStyle.currentDate);
  const firstDate = lastDate.minus({day: mapStyle.timeRange});

  // Discard layers which are not visible
  // Discard datasets which are already loading
  // Discard datasets which does not need to (re)load
  const datasetsToLoad = visState.layers
    .filter((layer) => layer.config.isVisible)
    .filter((layer) => visState.streamedDatas.has(layer.config.dataId))
    .map((layer) => {
      const datasetId = layer.config.dataId;
      return visState.streamedDatas.get(datasetId)!;
    })
    .filter((streamedData) => {
      const isLoading = visState.loadingDatasets.has(streamedData.datasetId);
      return !isLoading;
    })
    .filter((streamedData) => streamedData.type === 'turtle-streamed-data')
    .filter((streamedData) => {
      const secureInterval = Interval.fromDateTimes(
        firstDate.minus(DOWNLOAD_DELAY),
        lastDate.plus(DOWNLOAD_DELAY)
      );
      const loadedChunk = streamedData.loadedTimeChunk;
      const overlaps = loadedChunk
        ? loadedChunk.contains(secureInterval.start!) && loadedChunk.contains(secureInterval.end!)
        : false;

      const nbTurtleMatch = streamedData.nbTurtle === visState.nbTurtles;

      const needToLoad = !overlaps || !nbTurtleMatch;
      return needToLoad;
    });

  const loadTasks = datasetsToLoad.map((streamedData) => {
    const intervalDuration = Interval.fromDateTimes(firstDate, lastDate).toDuration();
    const halfDuration: DurationLike = {day: Math.ceil(intervalDuration.as('day') / 2)};
    const requestInterval = Interval.fromDateTimes(
      firstDate.minus(halfDuration).minus(DOWNLOAD_DELAY),
      lastDate.plus(halfDuration).plus(DOWNLOAD_DELAY)
    );

    return Task.fromPromise(
      () =>
        fetchTurtleDataset(streamedData.config.id, requestInterval, visState.nbTurtles).then(
          (dataEntry): LoadedDataEntry => ({
            type: 'turtle-data',
            ...dataEntry
          })
        ),
      'FETCH_TURTLE_DATASET_TASK'
    )();
  });

  {
    const {loadingDatasets} = visState;
    for (const dataset of datasetsToLoad) {
      loadingDatasets.add(dataset.config.id);
    }
    visState = {
      ...visState,
      loadingDatasets
    };
  }

  const loadDatasetsTask = Task.all(loadTasks).bimap(
    (results: any[]) => loadingDataEnded({data: results}),
    (err: any) => console.error(err)
  );

  const newState = {
    ...state,
    visState
  };
  return withTask(newState, loadDatasetsTask);
};

const updateTurtleDataset = (
  visState: VisState,
  table: LoadedTurtleDataEntry['table'],
  datasetId: string
): VisState => {
  const data = processArrowTable(table);
  if (data === null) {
    console.error(`Dataset ${datasetId} : Failed to process dataset`);
    return visState;
  }

  const dataset: ProtoDataset = {
    data,
    info: {
      id: datasetId
    }
  };

  // Update Kepler dataset
  visState = updateVisDataUpdater(visState, {
    datasets: dataset,
    options: {
      autoCreateLayers: false
    }
  });

  return visState;
};

const updateTurtleStreamingData = (
  visState: VisState,
  nbTurtle: LoadedTurtleDataEntry['nbTurtle'],
  chunkTime: LoadedTurtleDataEntry['chunkTime'],
  datasetId: string
): VisState => {
  let {streamedDatas} = visState;
  let streamedData = streamedDatas.get(datasetId);
  if (streamedData === undefined || streamedData.type !== 'turtle-streamed-data') {
    throw new Error('Incompatible dataset');
  }

  const oldNbTurtle = streamedData.nbTurtle;

  // Update streamed data
  streamedData = {
    ...streamedData,
    loadedTimeChunk: chunkTime,
    nbTurtle
  };

  streamedDatas.set(datasetId, streamedData);

  visState = {
    ...visState,
    streamedDatas
  };

  if (oldNbTurtle !== nbTurtle) {
    visState = updateRandomColorPaletteUpdater(visState, {payload: {datasetId}});
  }

  return visState;
};

const updateTurtleLayer = (state: VisState, datasetId: string): VisState => {
  const {streamedDatas} = state;
  const streamedData = streamedDatas.get(datasetId)!;
  const layerIdx = streamedData.layerIdx;

  const oldLayer = state.layers[layerIdx];
  const dataset = state.datasets[datasetId];
  const geometryFieldIdx = dataset.fields.findIndex((field) => field.name === 'geometry');
  const turtleIdxFieldIdx = dataset.fields.findIndex((field) => field.name === 'turtle_idx');
  const dateFieldIdx = dataset.fields.findIndex((field) => field.name === 'date');
  const columns: LayerColumns = {
    geometry: {
      fieldIdx: geometryFieldIdx,
      value: 'geometry',
      optional: false
    },
    turtle_idx: {
      fieldIdx: turtleIdxFieldIdx,
      value: 'turtle_idx',
      optional: false
    },
    date: {
      fieldIdx: dateFieldIdx,
      value: 'date',
      optional: false
    }
  };

  // Same color for the trajectories and the nesting area
  let color: RGBColor;
  {
    const nestingId = `geojson-${datasetId}`;
    const nestingConfig = state.streamedDatas.get(nestingId);
    if (nestingConfig === undefined) {
      color = oldLayer.config.color;
    } else {
      const {layerIdx} = nestingConfig;
      const layer = state.layers[layerIdx];
      color = layer.config.color;
    }
  }

  state = layerConfigChangeUpdater(state, {
    newConfig: {
      ...oldLayer.config,
      dataId: datasetId,
      columns,
      color
    },
    oldLayer
  });

  return state;
};

const updateTurtleFilter = (
  visState: VisState,
  datasetId: string,
  currentDate: MapStyle['currentDate'],
  timeRange: MapStyle['timeRange']
): VisState => {
  const dataset = visState.datasets[datasetId];

  const isDataEmpty =
    dataset.dataContainer.numColumns() === 0 || dataset.dataContainer.numRows() === 0;
  if (isDataEmpty) {
    return visState;
  }

  const {streamedDatas} = visState;
  const streamedData = streamedDatas.get(datasetId);

  if (streamedData === undefined || streamedData.type !== 'turtle-streamed-data') {
    throw new Error('Invalid streamedData');
  }

  // Update filter
  let filterIdx = streamedData.timeFilterIdx;
  // Skip adding filters
  if (filterIdx === undefined) {
    visState = addFilterUpdater(visState, {
      dataId: datasetId
    });
    filterIdx = visState.filters.length - 1;
    streamedData.timeFilterIdx = filterIdx;

    visState = setFilterUpdater(visState, {
      idx: filterIdx,
      prop: 'name',
      value: 'date'
    });

    visState = setFilterUpdater(visState, {
      idx: filterIdx,
      prop: 'gpu',
      value: true
    });

    visState = setFilterAnimationTimeConfigUpdater(visState, {
      idx: filterIdx,
      config: {
        timezone: 'Europe/Paris'
      }
    });
  }

  const maxRangeDate = currentDate;
  const minRangeDate = DateTime.fromJSDate(maxRangeDate).minus({day: timeRange}).toJSDate();
  visState = setFilterAnimationTimeUpdater(visState, {
    idx: filterIdx,
    prop: 'value',
    value: [minRangeDate.getTime(), maxRangeDate.getTime()]
  });

  visState = setFilterViewUpdater(visState, {
    idx: filterIdx,
    view: 'side'
  });

  // Devrait marcher mais ne fais rien ?
  //visState.filters[filterIdx].plotType = 'lineChart';

  return visState;
};

export const loadingTurtleDataEndedUpdater = (
  visState: VisState,
  dataEntry: LoadedTurtleDataEntry,
  {currentDate, timeRange}: {currentDate: MapStyle['currentDate']; timeRange: MapStyle['timeRange']}
): VisState => {
  const {table, datasetId, chunkTime, nbTurtle} = dataEntry;

  visState = updateTurtleStreamingData(visState, nbTurtle, chunkTime, datasetId);
  visState = updateTurtleDataset(visState, table, datasetId);
  // Update filter after layer may broke view updating
  visState = updateTurtleFilter(visState, datasetId, currentDate, timeRange);
  visState = updateTurtleLayer(visState, datasetId);

  return visState;
};

export const loadingAdditionalDataUpdater = (
  visState: VisState,
  dataEntry: LoadedAdditionalDataEntry
): VisState => {
  const {json, config} = dataEntry;
  const datasetId = config.id;

  if (json === undefined) {
    console.error('Json is empty');
    return visState;
  }

  const data = processGeojson(json);
  if (data === null) {
    console.error(`Dataset ${datasetId} : Failed to process dataset`);
    return visState;
  }

  const dataset: ProtoDataset = {
    data,
    info: {
      id: datasetId,
      label: dataEntry.config.description
    }
  };

  visState = updateVisDataUpdater(visState, {
    datasets: dataset,
    options: {
      autoCreateLayers: false
    }
  });

  {
    let layerLabel: string = dataEntry.config.description;
    // Replace some values
    switch (layerLabel) {
      case 'EU':
        layerLabel = 'Europe';
        break;
      case 'WA':
        layerLabel = 'West Asia';
        break;
      case 'SA':
        layerLabel = 'South America';
        break;
      case 'PO':
        layerLabel = 'Polar';
        break;
      case 'NA':
        layerLabel = 'North America';
        break;
      case 'AS':
        layerLabel = 'Asia & Pacific';
        break;
      case 'AF':
        layerLabel = 'Africa';
        break;
    }
    const color = getColor();
    const geoJsonLayerConfig = {
      type: 'geojson',
      config: {
        dataId: datasetId,
        label: layerLabel,
        isVisible: true,
        columns: {
          geojson: '_geojson'
        },
        visConfig: {
          opacity: 0.1,
          filled: color,
          thickness: 1.5,
          color: color,
          radius: 10
        }
      }
    };
    visState = addLayerUpdater(visState, {config: geoJsonLayerConfig, datasetId});
  }

  {
    const {streamedDatas, layers} = visState;
    const layerIdx = layers.length - 1;
    const streamedData: StreamedData = {
      type: 'base-streamed-config',
      datasetId: config.id,
      config: {
        type: 'additional-config',
        ...config
      },
      layerIdx: layerIdx
    };
    streamedDatas.set(config.id, streamedData);
    visState = {
      ...visState,
      layers,
      streamedDatas
    };
  }

  {
    const {datasetGroups} = visState;
    let group = datasetGroups.get(config.datatype);
    if (group === undefined) {
      group = new Set<string>();
    }
    group.add(config.id);
    datasetGroups.set(config.datatype, group);

    visState = {
      ...visState,
      datasetGroups
    };
  }

  return visState;
};

export const loadingDataEndedUpdater = (
  keplerState: KeplerGlState,
  {payload}: {payload: LoadingDataEndedPayload}
): KeplerGlState => {
  const {data} = payload;
  let {visState} = keplerState;
  const {mapStyle} = keplerState;

  for (const dataEntry of data) {
    if (dataEntry.type === 'turtle-data') {
      {
        const {loadingDatasets} = visState;
        loadingDatasets.delete(dataEntry.datasetId);
        visState = {
          ...visState,
          loadingDatasets
        };
      }
      visState = loadingTurtleDataEndedUpdater(visState, dataEntry, {
        currentDate: mapStyle.currentDate,
        timeRange: mapStyle.timeRange
      });
    } else {
      visState = loadingAdditionalDataUpdater(visState, dataEntry);
    }
  }

  return {
    ...keplerState,
    visState
  };
};

export const loadingTurtleConfigsEndedUpdater = (
  visState: VisState,
  {payload}: {payload: LoadingTurtleConfigsEndedPayload}
): VisState => {
  const {turtleConfigs} = payload;

  const fetchTasks = turtleConfigs.map((config) => {
    const dataset: ProtoDataset = {
      data: {
        fields: [],
        cols: [],
        rows: []
      },
      info: {
        id: config.id,
        label: buildTurtleKeplerLayerLabel(config) + ' ' + config.location
      }
    };

    visState = updateVisDataUpdater(visState, {
      datasets: dataset,
      options: {
        autoCreateLayers: false
      }
    });

    const layerConfig = {
      type: 'arrowTripLayer',
      config: {
        dataId: config.id,
        id: buildTurtleKeplerLayerID(config),
        label: buildTurtleKeplerLayerLabel(config),
        isVisible: false,
        columns: {
          geometry: 'geometry',
          turtle_idx: 'turtle_idx',
          date: 'date'
        },
        visConfig: {
          colorRange: {
            name: 'Random colors',
            type: QUA,
            category: 'Individual',
            colors: generateRandomColorPalette(DEFAULT_NB_TURTLES)
          }
        }
      }
    };

    visState = addLayerUpdater(visState, {
      datasetId: config.id,
      config: layerConfig
    });

    {
      const {streamedDatas, layers} = visState;
      const layerIdx = layers.length - 1;
      const streamedData: StreamedData = {
        type: 'turtle-streamed-data',
        datasetId: config.id,
        config: {type: 'turtle-config', ...config},
        layerIdx: layerIdx,
        nbTurtle: 0,
        loadedTimeChunk: undefined,
        timeFilterIdx: undefined
      };
      streamedDatas.set(config.id, streamedData);
      visState = {
        ...visState,
        layers,
        streamedDatas
      };
    }

    {
      const {datasetGroups} = visState;
      let group = datasetGroups.get(config.location);
      if (group === undefined) {
        group = new Set<string>();
      }
      group.add(config.id);
      datasetGroups.set(config.location, group);

      visState = {
        ...visState,
        datasetGroups
      };
    }

    return Task.fromPromise(() => fetchNestingArea(config))();
  });

  const loadDatasetsTask = Task.all(fetchTasks).bimap(
    (results: any[]) => loadingNestEnded({data: results}),
    (err: any) => console.error(err)
  );

  return withTask(visState, loadDatasetsTask);
};

export const loadingAdditionalConfigsEndedUpdater = (
  visState: VisState,
  {payload}: {payload: LoadingAdditionalConfigsEndedPayload}
): VisState => {
  const {additionalConfigs} = payload;

  const loadTasks = additionalConfigs.map((config) => {
    return Task.fromPromise(
      () =>
        fetchAdditionalDataset(config).then(
          (dataEntry): LoadedDataEntry => ({
            type: 'additional-data',
            ...dataEntry
          })
        ),
      'FETCH_ADDITIONAL_DATASET_TASK'
    )();
  });

  const task = Task.all(loadTasks).bimap(
    (results: any[]) => loadingDataEnded({data: results}),
    (err: any) => console.error(err)
  );

  return withTask(visState, task);
};

export const loadingNestEndedUpdater = (
  visState: VisState,
  {payload}: {payload: LoadingNestEndedPayload}
): VisState => {
  const {data} = payload;

  for (const {config, geojson} of data) {
    if (!geojson) {
      console.error(`Failed to fetch nesting area for config ${config.id}`);
      continue;
    }

    const processedData = processGeojson(geojson);
    if (processedData === null) {
      console.error(`Error while processing nesting area for config ${config.id}`);
      continue;
    }

    const datasetId = `geojson-${config.id}`;

    {
      const turtleDataId = config.id;
      const turtleDataConfig = visState.streamedDatas.get(turtleDataId);
      let color: RGBColor | undefined;
      if (turtleDataConfig === undefined) {
        color = undefined;
      } else {
        const layerIdx = turtleDataConfig.layerIdx;
        const layer = visState.layers[layerIdx];
        color = layer.config.color;
      }
      const label = `Nesting Area ${config.location}`;
      visState = updateVisDataUpdater(visState, {
        datasets: {
          data: processedData,
          info: {
            id: datasetId,
            label,
            color
          }
        },
        options: {
          autoCreateLayers: false
        }
      });

      const {datasetGroups} = visState;
      let group = datasetGroups.get(config.location);
      if (group === undefined) {
        group = new Set<string>();
      }
      group.add(datasetId);
      datasetGroups.set(config.location, group);

      const geoJsonLayerConfig: {type: 'geojson'; config: DeepPartial<GeoJsonLayerConfig>} = {
        type: 'geojson',
        config: {
          dataId: datasetId,
          label: `Nesting Area`,
          isVisible: true,
          color: color,
          columns: {
            //@ts-ignore
            geojson: '_geojson'
          },
          visConfig: {
            stroked: true,
            filled: true,
            strokeColor: color,
            opacity: 0.35,
            thickness: 1.5,
            radius: 200
          },
          iconUrl: NESTING_ICON_URL
        }
      };

      visState = addLayerUpdater(visState, {
        datasetId,
        config: geoJsonLayerConfig
      });
    }

    {
      const {streamedDatas, layers} = visState;
      const layerIdx = layers.length - 1;
      const streamedData: StreamedData = {
        type: 'base-streamed-config',
        config: {
          type: 'nesting-area-config',
          ...config
        },
        datasetId: `geojson-${config.id}`,
        layerIdx: layerIdx
      };
      streamedDatas.set(datasetId, streamedData);
      visState = {
        ...visState,
        layers,
        streamedDatas
      };
    }
  }

  return visState;
};

export function setTurtleNumberUpdater(
  visState: VisState,
  {payload}: {payload: SetTurtleNumberAction}
): VisState {
  const {turtleNumber} = payload;

  visState = {
    ...visState,
    nbTurtles: turtleNumber
  };

  const task = checkDownloadDatasetsTask();

  return withTask(visState, task);
}

export function updateRandomColorPaletteUpdater(
  visState: VisState,
  {payload}: {payload: UpdateRandomColorPaletteAction}
): VisState {
  const {datasetId} = payload;
  const streamedData = visState.streamedDatas.get(datasetId);

  if (streamedData === undefined || streamedData.type !== 'turtle-streamed-data') {
    throw new Error('Uncompatible dataset');
  }

  const {layerIdx, nbTurtle} = streamedData;
  const layer = visState.layers[layerIdx];

  const layerConfig = layer.config;
  const visConfig = layerConfig.visConfig;
  const oldPalette = visConfig.colorRange.colors;

  const newPalette = generateRandomColorPalette(nbTurtle || 0, oldPalette);

  visState = layerConfigChangeUpdater(visState, {
    oldLayer: layer,
    newConfig: {
      ...layer.config,
      visConfig: {
        ...layer.config.visConfig,
        colorRange: {
          name: 'Random colors',
          type: QUA,
          category: 'Individual',
          colors: newPalette
        }
      }
    }
  });

  return visState;
}

function getColor(): Uint8Array {
  let color = new Uint8Array([
    Math.floor(Math.random() * 255),
    Math.floor(Math.random() * 255),
    Math.floor(Math.random() * 255)
  ]);

  return color;
}

const updateTimeFilters = (state: VisState, currentDate: Date, minRangeDate: Date): VisState => {
  for (const streamedData of state.streamedDatas.values()) {
    if (streamedData.type !== 'turtle-streamed-data') {
      continue;
    }
    const {timeFilterIdx} = streamedData;
    if (timeFilterIdx === undefined) {
      continue;
    }

    state = setFilterAnimationTimeUpdater(state, {
      idx: timeFilterIdx,
      prop: 'value',
      value: [minRangeDate.getTime(), currentDate.getTime()]
    });
  }

  return state;
};

function generateNewLayerId(baseId: string, date: Date): string {
  const layerId = `${baseId.split('-source')[0]}-${date.toISOString().split('T')[0]}-layer`;
  return layerId;
}

function generateUrlsForxDays(
  baseUrl: string,
  startDate: Date,
  sourceId: string,
  timestep: number
): {id: string; url: string}[] {
  let urls: {id: string; url: string}[] = [];
  for (let i = 0; i <= DAYS_TO_PREFETCH * timestep; i += timestep) {
    let date = new Date(startDate);
    date.setDate(date.getDate() + i);
    let id = generateNewLayerId(sourceId, date);
    urls.push({id: id, url: `${baseUrl}&time=${date.toISOString()}`});
  }
  return urls;
}

function initializeSourcesAndLayers(
  map: maplibregl.Map,
  baseUrl: string,
  sourceId: string,
  startDate: Date,
  sourceData: SourceDatas,
  timestep: number
) {
  map.removeLayer(`${sourceId.split('-source')[0]}-layer`);
  let urls = generateUrlsForxDays(baseUrl, startDate, sourceId, timestep);
  sourceData[sourceId] = urls;

  for (let {id, url} of urls) {
    let sourceId = id.replace('-layer', '-source');
    if (!map.getSource(sourceId)) {
      map.addSource(sourceId, {
        type: 'raster',
        tiles: [url],
        tileSize: 512
      });
    }
    if (!map.getLayer(id)) {
      map.addLayer({
        id: id,
        type: 'raster',
        source: sourceId
      });
    }
  }
}

// Fonction pour ajouter une couche pour une date spécifique
function addLayerForDate(
  map: maplibregl.Map,
  sourceId: string,
  baseUrl: string,
  currentDate: Date,
  offset: number
) {
  let date = new Date(currentDate);
  date.setDate(date.getDate() + offset);
  let layerId = generateNewLayerId(sourceId, date);
  let newSourceId = layerId.replace('-layer', '-source');
  let url = `${baseUrl.split('&time=')[0]}&time=${date.toISOString().split('T')[0]}`;

  if (!map.getSource(newSourceId)) {
    map.addSource(newSourceId, {
      type: 'raster',
      tiles: [url],
      tileSize: 512
    });
  }
  if (!map.getLayer(layerId)) {
    map.addLayer({
      id: layerId,
      type: 'raster',
      source: newSourceId
    });
  }
}

// Fonction pour extraire la date à partir de l'identifiant d'une couche
function extractDateFromLayerId(layerId: string): Date {
  let dateString = layerId.match(/\d{4}-\d{2}-\d{2}/)![0];
  return new Date(dateString);
}

// Fonction pour réorganiser les couches sur la carte
function reorderLayers(map: maplibregl.Map, sourceId: string, currentDate: Date) {
  // Obtenir et trier les identifiants de couche par date
  let layerIds = map.getStyle().layers.map((layer) => layer.id);
  let dateLayerIds = layerIds.filter((id) => id.startsWith(sourceId.split('-source')[0]));

  // Supprimer les couches dont la date est passée
  dateLayerIds.forEach((layerId) => {
    let layerDate = extractDateFromLayerId(layerId);
    if (layerDate < currentDate) {
      map.removeLayer(layerId);
      map.removeSource(layerId.replace('-layer', '-source'));
    }
  });

  // Réorganise les couches
  let newlayerIds = map.getStyle().layers.map((layer) => layer.id);
  let newdateLayerIds = newlayerIds
    .filter((id) => id.startsWith(sourceId.split('-source')[0]))
    .sort((a, b) => extractDateFromLayerId(b).getTime() - extractDateFromLayerId(a).getTime());

  newdateLayerIds.forEach((layerId) => {
    map.moveLayer(layerId);
  });
}

function updateMapLayers(
  map: maplibregl.Map,
  currentDate: Date,
  sourceData: SourceDatas,
  timestep: number
) {
  const currentDayIndex = currentDate.getDate() % DAYS_TO_PREFETCH;
  const styleMetadata: any | undefined = map.getStyle().metadata;
  const sourceToUpdate: string[] = styleMetadata?.['timedSources'] ?? [];

  for (let sourceId of sourceToUpdate) {
    let urls = sourceData[sourceId];
    if (!urls) continue;

    // Ajouter les sources et couches pour les prochains jours
    for (let i = 0; i <= DAYS_TO_PREFETCH * timestep; i += timestep) {
      addLayerForDate(map, sourceId, urls[currentDayIndex].url, currentDate, i);
    }

    // Réorganiser les couches pour la source actuelle
    reorderLayers(map, sourceId, currentDate);
  }
}

const updateRasterLayers = (state: MapState, currentDate: Date, timestep: number): MapState => {
  const {mapboxDatas} = state;

  if (mapboxDatas.length === 0) {
    return state;
  }

  for (let mapIdx = 0; mapIdx < mapboxDatas.length; ++mapIdx) {
    const mapData = mapboxDatas[mapIdx];
    let {sourceDatas, lastSourceId, mapref} = mapData;

    const sourceChanged = lastSourceId !== '' && !mapref.getSource(lastSourceId);
    if (sourceChanged) {
      sourceDatas = {};
      lastSourceId = '';
    }

    const styleMetadata: any | undefined = mapref.getStyle().metadata;
    const timedSources: string[] | undefined = styleMetadata?.['timedSources'];
    const isTimedLayer = Array.isArray(timedSources) && timedSources.length !== 0;
    if (!isTimedLayer) {
      continue;
    }

    const needInit =
      lastSourceId === '' ||
      !Object.keys(mapref.getStyle().sources).some((name) => name.match(/\d{4}-\d{2}-\d{2}/));
    if (needInit) {
      for (let sourceId of timedSources) {
        const source = mapref.getSource(sourceId);

        if (source === undefined || !(source instanceof RasterTileSource)) {
          continue;
        }

        lastSourceId = sourceId;
        const baseUrl = source.tiles[0].split('&time=')[0];
        initializeSourcesAndLayers(mapref, baseUrl, sourceId, currentDate, sourceDatas, timestep);
      }
    }

    updateMapLayers(mapref, currentDate, sourceDatas, timestep);

    mapboxDatas[mapIdx] = {
      lastSourceId,
      mapref,
      sourceDatas
    };
  }

  state = {
    ...state,
    mapboxDatas
  };

  return state;
};

export const updateDateUpdater = (
  state: KeplerGlState,
  {payload}: {payload: UpdateDatePayload}
): KeplerGlState => {
  const {currentDate, timerange} = payload;

  {
    let {mapStyle, uiState} = state;

    uiState = updateVisualDateUpdater(uiState, {
      payload: {
        newDateMs: currentDate.getTime()
      }
    });

    mapStyle = {
      ...mapStyle,
      currentDate: currentDate,
      timeRange: timerange
    };

    state = {
      ...state,
      mapStyle,
      uiState
    };
  }

  {
    let {visState} = state;
    const minRangeDate = DateTime.fromJSDate(currentDate).minus({days: timerange}).toJSDate();
    visState = updateTimeFilters(visState, currentDate, minRangeDate);
    state = {
      ...state,
      visState
    };
  }

  {
    let {mapState} = state;
    const timeStep = state.mapStyle.playerTimeStep;
    mapState = updateRasterLayers(mapState, currentDate, timeStep);
    state = {
      ...state,
      mapState
    };
  }

  const task = checkDownloadDatasetsTask();

  return withTask(state, task);
};

export const setTimebarDomainUpdater = (
  state: UiState,
  {payload}: {payload: SetTimebarDomainPayload}
): UiState => {
  const {newDomain} = payload;
  state = {
    ...state,
    timebarDomain: newDomain
  };

  return state;
};

export const startPlayerUpdater = (
  mapState: MapStyle,
  {payload}: {payload: undefined}
): MapStyle => {
  mapState = {
    ...mapState,
    isPlaying: true
  };

  let {isTimerExist} = mapState;
  if (isTimerExist) {
    return mapState;
  }

  mapState = {
    ...mapState,
    isTimerExist: true
  };
  const task = DELAY_TASK(PLAYER_TICKRATE_MS).map(playerTimerTick);

  return withTask(mapState, task);
};

export const stopPlayerUpdater = (
  mapState: MapStyle,
  {payload}: {payload: undefined}
): MapStyle => {
  mapState = {
    ...mapState,
    isPlaying: false
  };

  return mapState;
};

const playerTimerUpdateDate = (mapState: MapStyle): MapStyle => {
  const {currentDate, timeRange, playerTimeStep} = mapState;

  const newDate = DateTime.fromJSDate(currentDate).plus({days: playerTimeStep});

  const task = ACTION_TASK().map(() =>
    updateDate({
      currentDate: newDate.toJSDate(),
      timerange: timeRange
    })
  );

  return withTask(mapState, task);
};

const playerTimerRestart = (mapState: MapStyle): MapStyle => {
  let {isPlaying} = mapState;

  let task: Task | undefined;
  if (isPlaying) {
    task = DELAY_TASK(PLAYER_TICKRATE_MS).map(playerTimerTick);
  } else {
    mapState = {
      ...mapState,
      isTimerExist: false
    };
  }

  if (task === undefined) {
    return mapState;
  } else {
    return withTask(mapState, task);
  }

  return mapState;
};

export const playerTimerTickUpdater = (mapState: MapStyle, {payload}: {payload: undefined}) => {
  mapState = playerTimerUpdateDate(mapState);
  mapState = playerTimerRestart(mapState);

  return mapState;
};

export const updateVisualDateUpdater = (
  state: UiState,
  {payload}: {payload: UpdateVisualDatePayload}
): UiState => {
  const {newDateMs} = payload;

  state = {
    ...state,
    timebarCurrentDateMs: newDateMs
  };

  return state;
};

export const setPlayerTimeStepUpdater = (
  mapStyle: MapStyle,
  {payload}: {payload: SetPlayerTimeStepPayload}
): MapStyle => {
  const {newTimeStep} = payload;

  mapStyle = {
    ...mapStyle,
    playerTimeStep: newTimeStep
  };

  return mapStyle;
};

export const updateRasterLayerUpdater = (
  state: KeplerGlState,
  {payload}: {payload: undefined}
): KeplerGlState => {
  const {currentDate, playerTimeStep} = state.mapStyle;
  let {mapState} = state;
  updateRasterLayers(mapState, currentDate, playerTimeStep);

  state = {
    ...state,
    mapState
  };
  return state;
};
