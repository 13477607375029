// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Base from '../base';

export default class ZoomBlur extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-zoom-blur'
  };

  render() {
    return (
      <Base {...this.props}>
        <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_129_11272)">
            <path d="M14.0436 8.79612C14.0776 8.53557 14.0951 8.26984 14.0951 8.00002C14.0951 7.7302 14.0776 7.46448 14.0436 7.20393L13.2881 7.30248C13.3179 7.53044 13.3332 7.76324 13.3332 8.00002C13.3332 8.2368 13.3179 8.46961 13.2881 8.69757L14.0436 8.79612Z" />
            <path d="M13.6326 5.66684C13.4271 5.17131 13.1582 4.70876 12.8359 4.28934L12.2317 4.75359C12.5139 5.12082 12.7492 5.52552 12.9288 5.95869L13.6326 5.66684Z" />
            <path d="M11.7106 3.16406C11.2912 2.84175 10.8286 2.57278 10.3331 2.36729L10.0412 3.07109C10.4744 3.25072 10.8791 3.48599 11.2463 3.76819L11.7106 3.16406Z" />
            <path d="M8.796 1.9563C8.53544 1.92232 8.26972 1.90479 7.9999 1.90479C7.73008 1.90479 7.46436 1.92232 7.20381 1.9563L7.30236 2.71181C7.53032 2.68207 7.76312 2.66669 7.9999 2.66669C8.23668 2.66669 8.46949 2.68207 8.69745 2.71181L8.796 1.9563Z" />
            <path d="M5.66672 2.3673C5.17119 2.57278 4.70864 2.84175 4.28922 3.16406L4.75347 3.76819C5.1207 3.48599 5.5254 3.25072 5.95857 3.07109L5.66672 2.3673Z" />
            <path d="M3.16394 4.28934C2.84163 4.70876 2.57266 5.17131 2.36717 5.66684L3.07096 5.95869C3.25059 5.52552 3.48587 5.12083 3.76807 4.75359L3.16394 4.28934Z" />
            <path d="M1.90466 8.00002C1.90466 7.7302 1.92219 7.46448 1.95618 7.20393L2.71169 7.30248C2.68195 7.53044 2.66657 7.76324 2.66657 8.00002C2.66657 8.2368 2.68195 8.46961 2.71169 8.69757L1.95618 8.79612C1.9222 8.53557 1.90466 8.26984 1.90466 8.00002Z" />
            <path d="M2.36717 10.3332C2.57266 10.8287 2.84163 11.2913 3.16394 11.7107L3.76807 11.2465C3.48587 10.8792 3.25059 10.4745 3.07096 10.0414L2.36717 10.3332Z" />
            <path d="M4.28922 12.836C4.70864 13.1583 5.17119 13.4273 5.66672 13.6328L5.95857 12.929C5.5254 12.7493 5.1207 12.5141 4.75347 12.2319L4.28922 12.836Z" />
            <path d="M7.20381 14.0437C7.46436 14.0777 7.73008 14.0953 7.9999 14.0953C8.26972 14.0953 8.53544 14.0777 8.796 14.0437L8.69745 13.2882C8.46949 13.318 8.23668 13.3334 7.9999 13.3334C7.76312 13.3334 7.53032 13.318 7.30236 13.2882L7.20381 14.0437Z" />
            <path d="M10.3331 13.6328C10.8286 13.4273 11.2912 13.1583 11.7106 12.836L11.2463 12.2319C10.8791 12.5141 10.4744 12.7493 10.0412 12.929L10.3331 13.6328Z" />
            <path d="M12.8359 11.7107C13.1582 11.2913 13.4271 10.8287 13.6326 10.3332L12.9288 10.0414C12.7492 10.4745 12.5139 10.8792 12.2317 11.2465L12.8359 11.7107Z" />
            <path d="M8.28668 4.68247L8.0606 4.85717L7.83853 4.6774C7.89349 4.6095 7.97649 4.57048 8.06383 4.57147C8.15118 4.57246 8.23327 4.61335 8.28668 4.68247Z" />
            <path d="M7.93939 9.90479C8.45103 9.90479 8.86579 9.49003 8.86579 8.97839H9.43722C9.43722 9.80562 8.76662 10.4762 7.93939 10.4762V9.90479Z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.0606 4.85717C7.83853 4.6774 7.83857 4.67735 7.83853 4.6774L7.83796 4.6781L7.83664 4.67973L7.83182 4.68573L7.81376 4.70836C7.7981 4.72807 7.77537 4.75687 7.74657 4.79388C7.68898 4.86788 7.60708 4.97477 7.50895 5.10739C7.31292 5.37232 7.05107 5.74136 6.78871 6.15687C6.5269 6.57152 6.2613 7.0376 6.0603 7.49604C5.86179 7.94878 5.71428 8.42049 5.71428 8.83877C5.71428 9.62005 5.96677 10.2475 6.40214 10.6801C6.83609 11.1113 7.42742 11.3247 8.0606 11.3247C9.36544 11.3247 10.4069 10.3023 10.4069 8.83877C10.4069 8.44807 10.2574 7.98965 10.0603 7.54592C9.85985 7.09454 9.59494 6.62569 9.33374 6.20433C9.07197 5.78205 8.81069 5.40228 8.61509 5.12835C8.51719 4.99124 8.43549 4.8803 8.3781 4.80341C8.3494 4.76496 8.32676 4.735 8.31119 4.71452L8.29326 4.69102L8.2885 4.68483L8.28668 4.68247C8.28664 4.68242 8.28668 4.68247 8.0606 4.85717ZM8.056 5.32981C8.02866 5.36611 7.99933 5.40535 7.9683 5.44728C7.77798 5.7045 7.52468 6.06158 7.27188 6.46195C7.01854 6.86319 6.76899 7.30275 6.58363 7.7255C6.39578 8.15394 6.28571 8.53897 6.28571 8.83877C6.28571 9.49365 6.4945 9.96631 6.80492 10.2748C7.11677 10.5846 7.55573 10.7533 8.0606 10.7533C9.03184 10.7533 9.83549 10.0051 9.83549 8.83877C9.83549 8.572 9.72738 8.20398 9.53812 7.77789C9.35225 7.35945 9.10201 6.91507 8.84806 6.5054C8.59468 6.09665 8.34081 5.72756 8.15005 5.46041C8.11661 5.41359 8.08515 5.36994 8.056 5.32981Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_129_11272">
              <rect width="16" height="16" />
            </clipPath>
          </defs>
        </svg>
      </Base>
    );
  }
}
