// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

// server url : http://mercator.geomatys.com/api

export const SERVER_URL = 'http://mercator.geomatys.com/api'

export const TURTLE_DATA_URL = SERVER_URL + '/turtles';
export const TURTLE_CONFIG_URL = SERVER_URL + '/list_config';

export const ADDITIONAL_CONFIG_URL = SERVER_URL + '/other_datas/all';
export const ADDITIONAL_DATA_URL = SERVER_URL + '/other_datas/data';

export const STYLE_URL = SERVER_URL + '/styles/get';
export const TIME_RANGE_URL = SERVER_URL + '/time_range';

export const NESTING_AREA_URL = SERVER_URL + '/nesting_area';
export const NESTING_ICON_URL = SERVER_URL + '/file/nesting-icon.png';

export const NB_TURTLE_RANGE: [number, number] = [1, 1000];
export const DEFAULT_NB_TURTLES: number = 100;
export const DAYS_TO_PREFETCH: number = 3;

export const PLAYER_TICKRATE_MS: number = 1000;
