// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component, useCallback} from 'react';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import memoize from 'lodash.memoize';

import {LAYER_BLENDINGS, OVERLAY_BLENDINGS, NB_TURTLE_RANGE} from '@kepler.gl/constants';
import {Layer, LayerClassesType} from '@kepler.gl/layers';
import {PanelListView, StreamedData} from '@kepler.gl/types';
import {Datasets} from '@kepler.gl/table';
import {
  UIStateActions,
  VisStateActions,
  MapStateActions,
  ActionHandler,
  StreamingActions
} from '@kepler.gl/actions';
import {FormattedMessage} from '@kepler.gl/localization';

import styled from 'styled-components';

import LayerListFactory from './layer-panel/layer-list';
import PanelTitleFactory from './panel-title';
import ItemSelector from '../common/item-selector/item-selector';
import {PanelLabel, SidePanelDivider, SidePanelSection} from '../common/styled-components';
import {SidePanelItem} from '../types';
// import DatasetLayerGroupFactory from './layer-panel/dataset-layer-group';
// import PanelViewListToggleFactory from './panel-view-list-toggle';
// import DatasetSectionFactory from './layer-panel/dataset-section';
// import AddLayerButtonFactory from './layer-panel/add-layer-button';
// import InfoHelperFactory from '../common/info-helper';
import RangeSliderFactory from '../common/range-slider';

type LayerBlendingSelectorProps = {
  layerBlending: string;
  updateLayerBlending: ActionHandler<typeof VisStateActions.updateLayerBlending>;
} & WrappedComponentProps;

type OverlayBlendingSelectorProps = {
  overlayBlending: string;
  updateOverlayBlending: ActionHandler<typeof VisStateActions.updateOverlayBlending>;
  infoHelper: React.ReactNode;
} & WrappedComponentProps;

type LayerManagerProps = {
  datasets: Datasets;
  streamedDatas: Map<string, StreamedData>;
  datasetGroups: Map<string, Set<string>>;
  nbTurtles: number;
  layers: Layer[];
  layerOrder: string[];
  layerClasses: LayerClassesType;
  layerBlending: string;
  overlayBlending: string;
  uiStateActions: typeof UIStateActions;
  visStateActions: typeof VisStateActions;
  mapStateActions: typeof MapStateActions;
  showAddDataModal: () => void;
  removeDataset: ActionHandler<typeof UIStateActions.openDeleteModal>;
  showDatasetTable: ActionHandler<typeof VisStateActions.showDatasetTable>;
  updateTableColor: ActionHandler<typeof VisStateActions.updateTableColor>;
  setTurtleNumber: ActionHandler<typeof StreamingActions.setTurtleNumber>;
  panelListView: PanelListView;
  panelMetadata: SidePanelItem;
} & WrappedComponentProps;

export const LayerBlendingSelector = React.memo(
  ({layerBlending, updateLayerBlending, intl}: LayerBlendingSelectorProps) => {
    const labeledLayerBlendings = Object.keys(LAYER_BLENDINGS).reduce(
      (acc, current) => ({
        ...acc,
        [intl.formatMessage({id: LAYER_BLENDINGS[current].label})]: current
      }),
      {}
    );

    const onChange = useCallback(
      (blending) => updateLayerBlending(labeledLayerBlendings[blending]),
      [updateLayerBlending, labeledLayerBlendings]
    );

    return (
      <SidePanelSection>
        <PanelLabel>
          <FormattedMessage id="layerBlending.title" />
        </PanelLabel>
        <ItemSelector
          selectedItems={intl.formatMessage({id: LAYER_BLENDINGS[layerBlending].label})}
          options={Object.keys(labeledLayerBlendings)}
          multiSelect={false}
          searchable={false}
          onChange={onChange}
        />
      </SidePanelSection>
    );
  }
);
LayerBlendingSelector.displayName = 'LayerBlendingSelector';

const InfoHelperWrapper = styled.div`
  float: right;
`;

const OverlayBlendingSelectorTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OverlayBlendingSelector = React.memo(
  ({overlayBlending, updateOverlayBlending, intl, infoHelper}: OverlayBlendingSelectorProps) => {
    const labeledOverlayBlendings = Object.keys(OVERLAY_BLENDINGS).reduce(
      (acc, current) => ({
        ...acc,
        [intl.formatMessage({id: OVERLAY_BLENDINGS[current].label})]: current
      }),
      {}
    );

    const onChange = useCallback(
      (blending) => updateOverlayBlending(labeledOverlayBlendings[blending]),
      [updateOverlayBlending, labeledOverlayBlendings]
    );

    return (
      <SidePanelSection>
        <OverlayBlendingSelectorTitleRow>
          <PanelLabel>
            <FormattedMessage id="overlayBlending.title" />
          </PanelLabel>
          <InfoHelperWrapper>{infoHelper}</InfoHelperWrapper>
        </OverlayBlendingSelectorTitleRow>
        <ItemSelector
          selectedItems={intl.formatMessage({id: OVERLAY_BLENDINGS[overlayBlending].label})}
          options={Object.keys(labeledOverlayBlendings)}
          multiSelect={false}
          searchable={false}
          onChange={onChange}
        />
      </SidePanelSection>
    );
  }
);
OverlayBlendingSelector.displayName = 'OverlayBlendingSelector';

LayerManagerFactory.deps = [
  LayerListFactory,
  // DatasetLayerGroupFactory,
  // PanelViewListToggleFactory,
  PanelTitleFactory,
  // DatasetSectionFactory,
  // AddLayerButtonFactory,
  // InfoHelperFactory
  RangeSliderFactory
];

function LayerManagerFactory(
  LayerList: ReturnType<typeof LayerListFactory>,
  // DatasetLayerGroup: ReturnType<typeof DatasetLayerGroupFactory>,
  // PanelViewListToggle: ReturnType<typeof PanelViewListToggleFactory>,
  PanelTitle: ReturnType<typeof PanelTitleFactory>,
  // DatasetSection: ReturnType<typeof DatasetSectionFactory>,
  // AddLayerButton: ReturnType<typeof AddLayerButtonFactory>,
  // InfoHelper: ReturnType<typeof InfoHelperFactory>,
  RangeSlider: ReturnType<typeof RangeSliderFactory>
) {
  class LayerManager extends Component<LayerManagerProps> {
    constructor(props: LayerManagerProps) {
      super(props);
      this.state = {visualTurtleNumber: props.nbTurtles};
    }

    state: {
      visualTurtleNumber: number;
    };

    _addLayer = (dataset: string) => {
      const {visStateActions} = this.props;
      visStateActions.addLayer(undefined, dataset);
    };

    _togglePanelListView = (listView: string) => {
      const {uiStateActions} = this.props;
      uiStateActions.togglePanelListView({panelId: 'layer', listView});
    };

    _onTurtleNumberUpdate = (value: number[]) => {
      this.setState({
        visualTurtleNumber: value[1]
      });
    };

    _onTurtleNumberCommit = (value: number[]) => {
      const {setTurtleNumber} = this.props;
      const nbTurtle = value[1];
      setTurtleNumber({turtleNumber: nbTurtle});
    };

    render() {
      const {
        layers,
        datasets,
        intl,
        streamedDatas,
        datasetGroups,
        layerOrder,
        uiStateActions,
        visStateActions,
        mapStateActions
      } = this.props;

      const layerGroupsBuilder = memoize(
        (
          datasetGroups: Map<string, Set<string>>,
          streamedDatas: Map<string, StreamedData>,
          layers: Layer[]
        ) => {
          const layerGroups = new Map<string, Layer[]>(
            [...datasetGroups.entries()].map(([location, datasetIds]) => {
              const group = [...datasetIds.values()].map((datasetId) => {
                const layerIdx = streamedDatas.get(datasetId)!.layerIdx;
                const layer = layers[layerIdx];
                return layer;
              });
              return [location, group];
            })
          );
          return layerGroups;
        }
      );

      const layerGroups = layerGroupsBuilder(datasetGroups, streamedDatas, layers);
      const Groups = [...layerGroups.entries()].map(([key, layers]) => (
        <React.Fragment key={key}>
          <SidePanelSection>
            <PanelTitle className="layer-manager-title" title={key}></PanelTitle>
            <LayerList
              layers={layers}
              datasets={datasets}
              layerOrder={layerOrder}
              uiStateActions={uiStateActions}
              visStateActions={visStateActions}
              mapStateActions={mapStateActions}
              layerClasses={this.props.layerClasses}
              streamedDatas={streamedDatas}
            />
          </SidePanelSection>
          <SidePanelDivider />
        </React.Fragment>
      ));

      return (
        <div className="layer-manager">
          <RangeSlider
            range={NB_TURTLE_RANGE}
            isRanged={false}
            step={1}
            value0={NB_TURTLE_RANGE[0]}
            value1={this.state.visualTurtleNumber}
            onChange={this._onTurtleNumberUpdate}
            onCommit={this._onTurtleNumberCommit}
          ></RangeSlider>
          {Groups}
          <SidePanelSection>
            <LayerBlendingSelector
              layerBlending={this.props.layerBlending}
              updateLayerBlending={visStateActions.updateLayerBlending}
              intl={intl}
            />
          </SidePanelSection>
        </div>
      );
    }
  }
  return injectIntl(LayerManager);
}

export default LayerManagerFactory;
