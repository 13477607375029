// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

/**
 * Follow the model of './data-table-modal.tsx'
 */

import styled from 'styled-components';
import React, {Component} from 'react';
import {FormattedMessage, injectIntl, IntlShape, WrappedComponentProps} from 'react-intl';
import { ASSETS_ROUTE } from '@kepler.gl/constants';

export const EU_FLAG_URL: string = ASSETS_ROUTE + 'eu-flag.png';
export const PRESENTATION_ILLUSTRATION_URL: string = ASSETS_ROUTE + 'pres-illustration.png';
export const MERCATOR_LOGO: string = ASSETS_ROUTE + 'mercator-logo.png';
export const UPWELL_LOGO: string = ASSETS_ROUTE + 'upwell-logo.png';
export const COPERNICUS_LOGO: string = ASSETS_ROUTE + 'copernicus-logo.png';

enum Tab {
  PresentationTab,
  HelpTab
}

const HelpModalContainer = styled.div`
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  margin: min(20px, 1%);
`;

const HelpModalTabContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
`;

const StyledLink = styled.a`
  &&&
  {
    color: #69AFC6;
    text-decoration: underline;

    :hover
    {
      color: #7BCCE5;
    }

  }
`;

const FloatingImageContainer = styled.div`
  float: left;

  margin-top: 16%;
  shape-outside: padding-box;
`

const FloatingImage = styled.img`
  width: 384px
  height: 288px;

  margin-right: 16px;
`

//transform: translate(0, 50%);

const PresentationContainer = styled.div`
  display: inline-block;
  white-space: pre-wrap;
`

const PresentationFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 16px

  > * {
    margin: 4px;
  }
`

export const HelpModalTab = styled.div<{active?: boolean}>`
  align-items: center;
  border-bottom: 3px solid ${({active = false}) => (active ? 'black' : 'transparent')};
  cursor: pointer;
  display: flex;
  height: 35px;
  margin: 0 3px;
  padding: 0 5px;

  :hover {
    border-bottom: 3px solid black;
  }
`;

export type HelpModalProps = {} & WrappedComponentProps;

HelpModalFactory.deps = [];

const PresentationTab: React.FC<{intl: IntlShape}> = ({intl}) => {
  return (
    <>
      <h1>{intl.formatMessage({id: 'helpModal.presentation.title'})}</h1>
      <PresentationContainer>
        <FloatingImageContainer>
          <FloatingImage src={PRESENTATION_ILLUSTRATION_URL} alt="Presentation illustration" />
        </FloatingImageContainer>
        <p>
          <FormattedMessage id='helpModal.presentation.content' values={{
            viewerTitle: <b>Simulated Sea Turtle Swimways</b>,
            cmsLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://marine.copernicus.eu/"><b>EU Copernicus Marine Service</b></StyledLink>,
            mercLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.mercator-ocean.eu/"><b>Mercator Ocean International</b></StyledLink>,
            cmsBold: <b>Copernicus Marine Service</b>,
            ibmLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.sciencedirect.com/topics/earth-and-planetary-sciences/individual-based-model">Individual-Based Model (IBM)</StyledLink>,
            upwellLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.upwell.org/"><b>Upwell</b></StyledLink>,
            fimsLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://doi.org/10.3389/fmars.2022.959366">https://doi.org/10.3389/fmars.2022.959366</StyledLink>,
            moreInfoLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://marine.copernicus.eu/news/protecting-endangered-sea-turtles-copernicus-marine-service">https://marine.copernicus.eu/news/protecting-endangered-sea-turtles-copernicus-marine-service</StyledLink>,
            geomatysLink: <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.geomatys.com/"><b>Geomatys</b></StyledLink>
          }} />
        </p>
      </PresentationContainer>
      <PresentationFooter>
          <img src={EU_FLAG_URL} height="48" alt="EU Flag" />
          <img src={COPERNICUS_LOGO} height="48" alt="Copernicius Logo" />
          <img src={MERCATOR_LOGO} height="48" alt="Mercator Logo" />
          <b><FormattedMessage id="helpModal.presentation.collab" /></b>
          <img src={UPWELL_LOGO} height="48" alt="Upwell Logo" />
        </PresentationFooter>
    </>
  );
};

const HelpTab: React.FC<{intl: IntlShape}> = ({intl}) => (
  <>
    <h1>{intl.formatMessage({id: 'helpModal.help.title'})}</h1>
    <p> Empty </p>
  </>
);

export function HelpModalFactory() {
  class HelpModal extends Component<HelpModalProps> {
    constructor(props: HelpModalProps) {
      super(props);

      this.state = {
        currentTab: Tab.PresentationTab
      };
    }

    state: {
      currentTab: Tab;
    };

    render() {
      const {intl} = this.props;
      const CurrentTab = () => (
        <>
          {this.state.currentTab === Tab.PresentationTab ? (
            <PresentationTab intl={intl} />
          ) : (
            <HelpTab intl={intl} />
          )}
        </>
      );
      return (
        <HelpModalContainer>
          <HelpModalTabContainer>
            <HelpModalTab
              active={this.state.currentTab === Tab.PresentationTab}
              onClick={() => this.setState({currentTab: Tab.PresentationTab})}
            >
              {intl.formatMessage({id: 'helpModal.presentation.title'})}
            </HelpModalTab>
            <HelpModalTab
              active={this.state.currentTab === Tab.HelpTab}
              onClick={() => this.setState({currentTab: Tab.HelpTab})}
            >
              {intl.formatMessage({id: 'helpModal.help.title'})}
            </HelpModalTab>
          </HelpModalTabContainer>
          <CurrentTab />
        </HelpModalContainer>
      );
    }
  }

  return injectIntl(HelpModal);
}

export default HelpModalFactory;
