// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Base from '../base';

export default class TiltShift extends Component {
  static propTypes = {
    /** Set the height of the icon, ex. '16px' */
    height: PropTypes.string
  };

  static defaultProps = {
    height: '16px',
    viewBox: '0 0 16 16',
    predefinedClassName: 'data-ex-icons-tilt-shift'
  };

  render() {
    return (
      <Base {...this.props}>
        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_129_11273)">
            <path d="M1.24084 2.58293L11.8227 1.24084L11.9186 1.99669L1.33671 3.33878L1.24084 2.58293Z" />
            <path d="M2.3837 11.7258L12.9656 10.3837L13.0615 11.1396L2.47956 12.4817L2.3837 11.7258Z" />
            <path d="M7.28677 3.68251L7.06069 3.85721L6.83861 3.67744C6.89358 3.60955 6.97657 3.57053 7.06392 3.57152C7.15126 3.5725 7.23336 3.61339 7.28677 3.68251Z" />
            <path d="M6.93947 8.90484C7.45111 8.90484 7.86588 8.49007 7.86588 7.97843H8.43731C8.43731 8.80567 7.7667 9.47627 6.93947 9.47627V8.90484Z" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.06069 3.85721C6.83861 3.67744 6.83861 3.67744 6.83861 3.67744L6.83673 3.67978L6.83191 3.68577L6.81385 3.70841C6.79819 3.72811 6.77545 3.75692 6.74666 3.79393C6.68907 3.86793 6.60717 3.97482 6.50904 4.10743C6.313 4.37237 6.05115 4.7414 5.7888 5.15692C5.52699 5.57156 5.26139 6.03764 5.06038 6.49608C4.86188 6.94883 4.71437 7.42054 4.71437 7.83882C4.71437 8.62009 4.96685 9.24753 5.40223 9.68015C5.83617 10.1113 6.42751 10.3247 7.06069 10.3247C8.36553 10.3247 9.407 9.3024 9.407 7.83882C9.407 7.44811 9.25753 6.9897 9.06043 6.54597C8.85994 6.09458 8.59503 5.62574 8.33383 5.20438C8.07206 4.7821 7.81078 4.40233 7.61518 4.12839C7.51727 3.99128 7.43558 3.88035 7.37819 3.80346C7.34949 3.76501 7.32685 3.73505 7.31128 3.71457L7.29334 3.69107L7.28859 3.68488L7.28677 3.68251C7.28677 3.68251 7.28677 3.68251 7.06069 3.85721ZM7.05609 4.32985C7.02874 4.36615 6.99942 4.40539 6.96839 4.44733C6.77807 4.70454 6.52476 5.06163 6.27197 5.462C6.01863 5.86323 5.76907 6.3028 5.58372 6.72554C5.39586 7.15399 5.2858 7.53902 5.2858 7.83882C5.2858 8.49369 5.49459 8.96636 5.80501 9.27481C6.11685 9.58468 6.55582 9.75331 7.06069 9.75331C8.03192 9.75331 8.83557 9.00512 8.83557 7.83882C8.83557 7.57205 8.72747 7.20403 8.53821 6.77793C8.35234 6.35949 8.1021 5.91512 7.84815 5.50545C7.59476 5.09669 7.34089 4.72761 7.15013 4.46046C7.1167 4.41364 7.08524 4.36999 7.05609 4.32985Z"
            />
          </g>
          <defs>
            <clipPath id="clip0_129_11273">
              <rect width="12.1905" height="12.1905" transform="translate(0.904785 0.904785)" />
            </clipPath>
          </defs>
        </svg>
      </Base>
    );
  }
}
