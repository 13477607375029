// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React, {Component} from 'react';
import {createSelector} from 'reselect';
import MapStyleSelectorFactory from './map-style-panel/map-style-selector';
import LayerGroupSelectorFactory from './map-style-panel/map-layer-selector';
import {SidePanelSection} from '../common/styled-components';
import PanelTitleFactory from '../side-panel/panel-title';
import {Trash} from '../common/icons';
import {PanelMeta} from './common/types';
import {injectIntl, WrappedComponentProps} from 'react-intl';
import {MapStyle} from '@kepler.gl/reducers';
import {MapStyleActions} from '@kepler.gl/actions';
import {MapStyles} from '@kepler.gl/types';

export type MapManagerProps = {
  mapStyle: MapStyle;
  mapStyleActions: {
    mapStyleChange: typeof MapStyleActions.mapStyleChange;
    mapConfigChange: typeof MapStyleActions.mapConfigChange;
    set3dBuildingColor: typeof MapStyleActions.set3dBuildingColor;
    setBackgroundColor: typeof MapStyleActions.setBackgroundColor;
    removeCustomMapStyle: typeof MapStyleActions.removeCustomMapStyle;
    rasterLayerChange: typeof MapStyleActions.rasterLayerChange;
  };
  showAddMapStyleModal: () => void;
  panelMetadata: PanelMeta;
} & WrappedComponentProps;

MapManagerFactory.deps = [MapStyleSelectorFactory, LayerGroupSelectorFactory, PanelTitleFactory];

function MapManagerFactory(
  MapStyleSelector: ReturnType<typeof MapStyleSelectorFactory>,
  LayerGroupSelector: ReturnType<typeof LayerGroupSelectorFactory>,
  PanelTitle: ReturnType<typeof PanelTitleFactory>
) {
  class MapManager extends Component<MapManagerProps> {
    state = {
      isSelecting: false
    };

    _toggleSelecting = () => {
      this.setState({isSelecting: !this.state.isSelecting});
    };

    _selectStyle = (val: string) => {
      const {mapStyleActions} = this.props;
      const {mapStyleChange} = mapStyleActions;
      mapStyleChange(val);
      this._toggleSelecting();
    };

    _selectRaster = (mapIndex: number) => (val: string) => {
      const {mapStyleActions, mapStyle} = this.props;
      const {selectedRasters} = mapStyle;
      const currentStyle = selectedRasters[mapIndex];
      const {rasterLayerChange} = mapStyleActions;

      const deselectRaster = val === currentStyle;
      if (deselectRaster) {
        val = '';
      }
      rasterLayerChange(val, mapIndex);
    };

    getCustomMapStylesActions = createSelector(
      (props: MapManagerProps) => props.mapStyle.mapStyles,
      (props: MapManagerProps) => props.mapStyleActions,
      (mapStyles: MapStyles, mapStyleActions) => {
        const actionsPerCustomStyle = {};
        Object.values(mapStyles)
          .filter((mapStyle) => {
            return Boolean(mapStyle.custom);
          })
          .forEach(({id}) => {
            actionsPerCustomStyle[id] = [
              {
                id: `remove-map-style-${id}`,
                IconComponent: Trash,
                tooltip: 'tooltip.removeBaseMapStyle',
                onClick: () => mapStyleActions.removeCustomMapStyle({id})
              }
            ];
          });
        return actionsPerCustomStyle;
      }
    );

    /*
     I can't comment inside the render function so this a block of code I deleted from our custom kepler that might be useful later if we want a add map button


            <Button className="add-map-style-button" onClick={showAddMapStyleModal}>
              <Add height="12px" />
              <FormattedMessage id={'mapManager.addMapStyle'} />
            </Button>
    */
    render() {
      const {mapStyle} = this.props;
      const customMapStylesActions = this.getCustomMapStylesActions(this.props);

      const Selectors = [...mapStyle.selectedRasters.entries()].map(([idx, styleType]) => (
        <SidePanelSection key={`map-${idx}-selector`}>
          <PanelTitle title={`Map ${idx + 1}`}></PanelTitle>
          <MapStyleSelector
            mapStyles={mapStyle.mapStyles}
            displayableStyles={mapStyle.rasterLayers}
            isSelecting={this.state.isSelecting}
            onChange={this._selectRaster(idx)}
            currentStyleId={mapStyle.selectedRasters[idx]}
            toggleActive={this._toggleSelecting}
            customMapStylesActions={customMapStylesActions}
          />
        </SidePanelSection>
      ));

      return (
        <div className="map-style-panel">
          <PanelTitle
            className="map-manager-title"
            title="Raster layers" //{intl.formatMessage({id: panelMetadata.label})}
          ></PanelTitle>
          <SidePanelSection key="base-map-selector">
            <PanelTitle title="Base map"></PanelTitle>
            <MapStyleSelector
              mapStyles={mapStyle.mapStyles}
              displayableStyles={mapStyle.baseMaps}
              isSelecting={this.state.isSelecting}
              onChange={this._selectStyle}
              currentStyleId={this.props.mapStyle.styleType}
              toggleActive={this._toggleSelecting}
              customMapStylesActions={customMapStylesActions}
            />
          </SidePanelSection>
          {Selectors}
        </div>
      );
    }
  }
  return injectIntl(MapManager);
}

export default MapManagerFactory;
