// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {NumericArray} from '@math.gl/types';
import {AccessorFunction, DefaultProps} from '@deck.gl/core/typed';
import {PathLayer, PathLayerProps} from '@deck.gl/layers/typed';

const defaultProps: DefaultProps<PeriodicTripsLayerProps> = {
  trailLength: {type: 'number', value: 120, min: 0},
  period: {type: 'number', value: 120, min: 0},
  currentTime: {type: 'number', value: 0, min: 0},
  getTimestamps: {type: 'accessor', value: (d: any) => d.timestamps}
};

/** All properties supported by TripsLayer. */
export type PeriodicTripsLayerProps<DataT = unknown> = _PeriodicTripsLayerProps<DataT> &
  PathLayerProps<DataT>;

/** Properties added by TripsLayer. */
type _PeriodicTripsLayerProps<DataT = unknown> = {
  /**
   * Trail length.
   * @default 120
   */
  trailLength?: number;
  /**
   * Period to send points.
   * @default 120
   */
  period?: number;
  /**
   * The current time of the frame.
   * @default 0
   */
  currentTime?: number;
  /**
   * Timestamp accessor.
   */
  getTimestamps?: AccessorFunction<DataT, NumericArray>;
};

/** Render animated paths that represent vehicle trips. */
export default class PeriodicTripsLayer<DataT = any, ExtraProps extends {} = {}> extends PathLayer<
  DataT,
  Required<_PeriodicTripsLayerProps<DataT>> & ExtraProps
> {
  static layerName = 'PeriodicTripsLayer';
  static defaultProps = defaultProps;

  getShaders() {
    const shaders = super.getShaders();
    shaders.inject = {
      'vs:#decl': `\
uniform float trailLength;
uniform float period;
in float instanceTimestamps;
in float instanceNextTimestamps;
out float vTime;
`,
      // Timestamp of the vertex
      'vs:#main-end': `\
vTime = instanceTimestamps + (instanceNextTimestamps - instanceTimestamps) * vPathPosition.y / vPathLength;
`,
      'fs:#decl': `\
uniform float trailLength;
uniform float period;
uniform float currentTime;
in float vTime;
`,
      // Drop the segments outside of the time window
      'fs:#main-start': `\
if(
  // Feel like it could be optimized by interpolating on the vertex side
  mod(vTime, period) < mod(currentTime, period) - trailLength
) {
  discard;
}
`,
      // Fade the color (currentTime - 100%, end of trail - 0%)
      'fs:DECKGL_FILTER_COLOR': `\
color.a *= 1.0 - mod(currentTime - vTime, period) / trailLength;
`
    };
    return shaders;
  }

  initializeState() {
    super.initializeState();

    const attributeManager = this.getAttributeManager();
    attributeManager!.addInstanced({
      timestamps: {
        size: 1,
        accessor: 'getTimestamps',
        shaderAttributes: {
          instanceTimestamps: {
            vertexOffset: 0
          },
          instanceNextTimestamps: {
            vertexOffset: 1
          }
        }
      }
    });
  }

  draw(params: any) {
    const {trailLength, currentTime, period} = this.props;

    params.uniforms = {
      ...params.uniforms,
      trailLength,
      currentTime,
      period
    };

    super.draw(params);
  }
}
