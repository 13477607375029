// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {rgbToHex} from '@kepler.gl/utils';

import React, {Component, ReactNode} from 'react';
import styled from 'styled-components';
import {scaleLinear} from 'd3-scale';
import memoize from 'lodash.memoize';

export type Color = [number, number, number];

export type MarkerData = {
  color: Color;
  pos: number;
};

const Marker = styled.div(
  ({pos, color}: {pos: number; color: string}) => `
  background-color: ${color};
  width: 5px;
  height: 15px;
  position: absolute;
  transform: translateX(-50%);
  left: ${pos}%;
  bottom: 100%;
`
);

const SliderMarkersWrapper = styled.div`
  position: relative;
`;

export type SliderMarkersProps = {
  domain: [number, number];
  datas: MarkerData[];
};
export class SliderMarkers extends Component<SliderMarkersProps> {
  constructor(props: SliderMarkersProps) {
    super(props);
  }

  private getScale = memoize((domain: [number, number]) => scaleLinear().domain(domain));
  private formatData = memoize(
    (datas: MarkerData[], scale: (p1: number) => number): {pos: number; color: string}[] =>
      datas.map((data) => {
        const color = rgbToHex(data.color);
        let scaledPos = scale(data.pos) * 100;
        if (scaledPos < 0) {
          scaledPos = 0;
        }

        return {
          pos: scaledPos,
          color
        };
      })
  );

  override render(): ReactNode {
    const {datas, domain} = this.props;

    const scale = this.getScale(domain);
    const formatDatas = this.formatData(datas, scale);

    return (
      <SliderMarkersWrapper>
        {formatDatas.map((data, idx) => (
          <Marker key={`indicator-${idx}`} pos={data.pos} color={data.color} />
        ))}
      </SliderMarkersWrapper>
    );
  }
}

export default SliderMarkers;
